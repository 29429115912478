import Types from "../type";
import { ISetting } from "../util/setting";
import { call } from "../util/call.api";
import { useRouter } from "next/router";
import { useEffect } from "react";

const collectionProduct = async (setting:ISetting) => 
{
	const result = await call(Types.COLLECTION_PRODUCT_SERVER, setting);
	
	return result;
}

const collectionProductVariant = async (setting:ISetting) => 
{
	const result = await call(Types.COLLECTION_PRODUCT_VARIANT_SERVER, setting);
	
	return result;
}

const getProduct = async (setting:ISetting) => 
{
	const result = await call(Types.GET_PRODUCT_SERVER, setting);
	
	return result;
}

const useGetStockTableProduct = (setting:ISetting, onSuccess:any) => 
{
	const router = useRouter();

	useEffect(() => 
	{
		call(Types.GET_STOCK_TABLE_SERVER, setting).then(result => 
		{
			onSuccess(result.data)
		});
	}, [router.asPath]);	
}

export { 
	collectionProduct, 
	collectionProductVariant,
	getProduct, 
	useGetStockTableProduct,	
}