import styles from "./styles.module.scss";
import { Newsletter } from "./Newsletter";
import useView from "../Hooks/useView";
import { Accordions } from "./Accordions";
import { FooterMenuDesk } from "./FooterMenuDesk";
import { Sac } from "./Sac";
import { Bottom } from "./Bottom";

export const Footer = ({ account, homePage }: any) => 
{
  const { innerWidth = 0 } = useView();

  return (
    <footer className={styles.footer}>
      <Newsletter />
      {innerWidth <= 780 ? <Accordions /> : <FooterMenuDesk />}
      <Sac account={account} />
      <Bottom />
    </footer>
  );
};
