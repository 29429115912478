import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MENU_FOOTER_STATIC, MENU_FOOTER_STATIC_TWO } from "../../setting/setting";

export const Accordions = () => {
  const router = useRouter();

  return (
    <section className={styles.accordions}>
      {MENU_FOOTER_STATIC && MENU_FOOTER_STATIC?.map((item: any, index: any) => (
        <Accordion disableGutters className={styles.accordion} key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {item?.label}
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {item?.children?.map((itemTwo: any, indexTwo: any) => (
                <li
                key={indexTwo}
                onClick={() => router.push(itemTwo?.url)}
                >
                  {itemTwo?.label}
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
      {MENU_FOOTER_STATIC_TWO && MENU_FOOTER_STATIC_TWO?.map((itemMenuTwo: any, indexMenuTwo) => (
        <Accordion disableGutters className={styles.accordion} key={indexMenuTwo}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {itemMenuTwo?.label}
          </AccordionSummary>
          <AccordionDetails>
            <ul>
            {itemMenuTwo?.children?.map((itemChildMenuTwo: any, indexChildMenuTwo: any) => (
              <li
                key={indexChildMenuTwo}
                onClick={() => router.push(itemChildMenuTwo?.url)}
              >
                {itemChildMenuTwo?.label}
              </li>
            ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
      {/* <Accordion disableGutters className={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Ajuda
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Como Comprar</li>
            <li>Entrega</li>
            <li>Troca e Devolução</li>
            <li>Formas de Pagamento</li>
            <li>Perguntas Frequentes</li>
          </ul>
        </AccordionDetails>
      </Accordion> */}
    </section>
  );
};
