import styles from "./styles.module.scss";
import { Box, Skeleton } from "@material-ui/core";

export const ProductItemSkeletonCart = () => {
    return (
        <Box sx={{ width: 300 }} className={styles.productItemSkeletonCart}>
            <div className={styles.productActions}>
                <Skeleton animation="wave" variant="text" width={"95%"} height={130} />
            </div>
            <div className={styles.productLine}>
                <div className={styles.productActionsLine}>
                    <Skeleton animation="wave" variant="text" width={"100%"} height={40} />
                </div>
                <div className={styles.productActionsLine}>
                    <Skeleton animation="wave" variant="text" width={"100%"} height={25} />
                </div>
                <div className={styles.productActionsLine}>
                    <Skeleton animation="wave" variant="text" width={"100%"} height={25} />
                </div>
            </div>
        </Box>
    );
}