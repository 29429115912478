import styles from "./styles.module.scss";
import { GetStaticProps } from "next";
import { motion } from "framer-motion";
import { CartItem } from "../CartItem";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import withHeader from "../../utils/withHeader";
import { FULL_PRODUCT_SETTING, THEME_SETTING } from "../../setting/setting";;
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { collectionProduct } from "../../core-nextv3/product/product.api";
import { useCore } from "../../core-nextv3/core/core";
import { modalBagAnimation } from "../animation";
import { currencyMask } from "../../core-nextv3/util/mask";
import { FaTruck } from "react-icons/fa";
import { CartMessage } from "../CartMessage";
import { Gifts } from "../Gifts";

export const ModalBag = ({
	cartPage,
	pageGifts
}:any) => 
{
	const router                                        = useRouter();
	const [loadingCart, setLoadingCart]                 = useState(true);
	const [loadedTagManager, setLoadedTagManager]       = useState(false);
	const { cart, user, setIsOpenCart, isLoadingCart }  = useCore();
	
	useEffect(() => 
	{
		if(cart && !loadedTagManager)
		{
			setLoadedTagManager(true);
			tagManager4.cartView(cart, router.asPath, "Meu carrinho", "b2c", "ecommerce", window.orientation > -1 ? 'mobile' : 'desktop', user);
		}
	}, [cart]);

  return (
    <motion.div
      className={styles.modalMenuOverlay}
      variants={modalBagAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      onClick={() => setIsOpenCart(false)}
    >
      <div className={styles.modalMenu} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <GrFormClose onClick={() => setIsOpenCart(false)} />

          <div className={styles.center}>
            <p className={styles.title}>Carrinho de Compras ({cart?.items?.length || 0})</p>
            {/* <BsHandbag /> */}
          </div>

          <div className={styles.left}></div>
        </div>
		{cartPage?.published && (<CartMessage cartMessage={cartPage} />)}
		{cart?.freeShipping?.status && <section className={styles.cartFreeShipping}>
			<div className={styles.content}>
				{cart?.freeShipping?.diff != -1 && <>
					{cart?.freeShipping?.diff > 0 ? <>Falta {currencyMask(cart?.freeShipping?.diff)} para FRETE GRÁTIS! <FaTruck /></> : <>Você Ganhou FRETE GRÁTIS! <FaTruck /></>}
				</>}
				{cart?.freeShipping?.diffQuantity != -1 && <>
					{cart?.freeShipping?.diffQuantity > 0 ? <>Falta {cart?.freeShipping?.diffQuantity} produto(s) para FRETE GRÁTIS! <FaTruck /></> : <>Você Ganhou FRETE GRÁTIS! <FaTruck /></>}
				</>}
			</div>
		</section>}
		{pageGifts?.published && (
          <Gifts page={pageGifts} cart={cart} loadingCart={isLoadingCart} />
        )}
        <div className={styles.products}>
          <CartItem cart={cart} setModalBag={setIsOpenCart} user={user}/>
        </div>
      </div>
    </motion.div>
  );
};

export default ModalBag;

export const getStaticProps: GetStaticProps = () => withHeader(async (props: any) => 
{
	const productsResult = await(
		collectionProduct(FULL_PRODUCT_SETTING.merge({
			perPage: 1,
			where: [
				{
					field: "published",
					operator: "==",
					value: true,
				},
			]
		}))
	);

	// const cartMessageResult = await getDocument(TOP_CART_PAGE_SETTING)

	return {
		revalidate : THEME_SETTING.revalidate,
		props: {
			seo: props.seo.merge({ title: 'Carrinho' }),
			products : productsResult?.collection || [],
			// cartMessage : cartMessageResult?.data || [],
		},
	};
});