import Types from "../type";
import { ISetting } from "../util/setting";
import { call } from "../util/call.api";

const todayWeather = async () => 
{
	const result = await call(Types.WEATHER_SERVER);
	
	return result;
}

export { todayWeather }