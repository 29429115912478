import { Matrix } from "../model/matrix"
import { currencyMask } from "../util/mask"

const MAX_NUMBER = 99999;

// PEGA O PROMOTION OU O PRICE, MENOR VALOR
const productRealPrice = (product:any, list:any=null, mask=true):any =>
{
	let value = 0;	

	if(product)
	{
		if(product.promotionalPrice !== undefined && product.promotionalPrice > 0)
		{
			value = product.promotionalPrice;
		}		
		else if(product.price !== undefined && product.price > 0)
		{
			value = product.price;
		}		
		else if(product.priceTable && product.priceTable.data)
		{			
			let promotionalPrice : number = MAX_NUMBER;
			let price : number 			  = MAX_NUMBER;
			const priceTable 		      = new Matrix(product.priceTable);
			const data       		      = priceTable.getValueByListVariant(list);

			for(const key in data)
			{
				if(data[key].promotionalPrice > 0)
				{
					promotionalPrice = Math.min(data[key].promotionalPrice, promotionalPrice);
				}

				if(data[key].price > 0)
				{
					price = Math.min(data[key].price, price);
				}							
			}

			if(promotionalPrice > 0 && promotionalPrice !== MAX_NUMBER)
			{
				value = promotionalPrice;
			}
			else if(price > 0 && price !== MAX_NUMBER)
			{
				value = price;
			}
		}
		else
		{
			value = 0;
		}
	}

	return mask ? currencyMask(value) : value;
}

const productPromotionalPercentage = (product:any, list:any=null, mask=true) =>
{
	let value : any = 0;	

	if(product)
	{
		if(product.promotionalPrice !== undefined && product.price !== undefined 
			&& product.promotionalPrice > 0 && product.price > 0)
		{
			value = 100 - Math.round(product.promotionalPrice * 100 / product.price);
		}		
		else if(product.priceTable && product.priceTable.data)
		{
			let promotionalPrice : number = MAX_NUMBER;
			let price : number 			  = MAX_NUMBER;

			const priceTable 		   = new Matrix(product.priceTable);
			const data       		   = priceTable.getValueByListVariant(list);
 
			for(const key in data)
			{
					if(data[key].promotionalPrice > 0 && data[key].price > 0)
					{
							promotionalPrice = Math.min(data[key].promotionalPrice, promotionalPrice);
							price 			 = Math.min(data[key].price, price);
					}
			}
			
			if(promotionalPrice !== MAX_NUMBER && price !== MAX_NUMBER && promotionalPrice > 0 && price > 0)
			{
				value = 100 - Math.round(promotionalPrice * 100 / price);
			}			
		}			
	}

	return mask ? value + '%' : value;
}

const productPromotionalPrice = (product:any, list:any=null, mask=true) =>
{
	let value : any = 0;

	if(product)
	{
		if(product.promotionalPrice > 0)
		{
			value = product.promotionalPrice;
		}
		else if(product?.priceTable?.data)
		{
			let _promotionalPrice : number = MAX_NUMBER;
			const priceTable 		       = new Matrix(product.priceTable);
			const data       		       = priceTable.getValueByListVariant(list);

			for(const key in data)
			{
				// if(data[key].promotionalPrice > 0)
				// {
					
				// }
				_promotionalPrice = Math.min(data[key].promotionalPrice, _promotionalPrice);
				
				// SEMPRE QUE TIVER MAIS DE UMA CHAVE, PEGAR A PRIMEIRA! 
				//POIS PODE PEGAR CHAVES DE PREÇO E PROMOTIONAL PRICE DIFERENTES
				break; 
			}

			if(_promotionalPrice !== MAX_NUMBER)
			{
				value = _promotionalPrice;
			}
		}		
	}

	return mask ? currencyMask(value) : value;
}

const productPrice = (product:any, list:any=null, mask=true) =>
{
	let value : any = 0;

	if(product)
	{		
		if(product.price > 0)
		{
			value = product.price;
		}
		else if(product?.priceTable?.data)
		{
			let _price : number = MAX_NUMBER;
			const priceTable 	= new Matrix(product.priceTable);
			const data       	= priceTable.getValueByListVariant(list);

			for(const key in data)
			{
				// if(data[key].price > 0)
				// {
					
				// }
				_price = Math.min(data[key].price, _price);

				// SEMPRE QUE TIVER MAIS DE UMA CHAVE, PEGAR A PRIMEIRA! 
				//POIS PODE PEGAR CHAVES DE PREÇO E PROMOTIONAL PRICE DIFERENTES
				break; 
			}			
			
			if(_price !== MAX_NUMBER)
			{
				value = _price;
			}
		}			
	}

	return mask ? currencyMask(value) : value;
}

const priceInstallments = (price: number, maxInstallments: number, minValue: number) => 
{
	if(price)
	{
		const installmentPerPrice: number = price / minValue;
		let finalInstallments: number 	  = Math.floor(installmentPerPrice);
		let pricePerInstallment: number   = price / finalInstallments;
	  
		if(finalInstallments >= maxInstallments) 
		{
		  	finalInstallments = maxInstallments;
		  	pricePerInstallment = price / finalInstallments;
		}
	  
		if(finalInstallments <= 1) 
		{
		  	return;
		}

		return `${Number(finalInstallments)}x de ${currencyMask(Number(pricePerInstallment.toFixed(2)))} sem juros`;
	}
	else
	{
		return null;
	}	
};

const productInstallments = (product: any, installments: number, minInstallment: number, list:any=null) => 
{
	const price : number = productRealPrice(product, list, false);

	return priceInstallments(price, installments, minInstallment);
};

const productDiscount = (product: any, discount: number, list:any=null) => 
{
	const price : number = productRealPrice(product, list, false);

	let value = price - (price * (discount/100));

	return `${currencyMask(Number(value.toFixed(2)))}`;
};

const discount = (price: any, discount: number, mask=true) => 
{
	let value = price - (price * (discount/100));

	if(mask)
	{
		return `${currencyMask(Number(value.toFixed(2)))}`;
	}	

	return value;
};

const hasPaymentMethod = (type:string, installmentRule:any) => 
{
	if(installmentRule && installmentRule.paymentMethods)
	{
		for(const item of installmentRule.paymentMethods)
		{
			if(item.type && item.type.value == type)
			{
				return true;
			}
		}
	}

	return false;
};

const percentageByPaymentMethod = (type:string, installmentRule:any) => 
{
	if(installmentRule && installmentRule.paymentMethods)
	{
		for(const item of installmentRule.paymentMethods)
		{
			if(item.type && item.type.value == type)
			{
				return item.percentage;
			}
		}
	}

	return 0;
};

const calculateInstallments = (total:number, installmentRule:any, totalShipping=0) =>
{
	const subTotal     = total;
	const installments = [];

	if(installmentRule)
	{
		let amountInstallments : any = subTotal;

		const maxInterest = (subTotal * Math.pow(1 + (installmentRule.interestRate / 100), installmentRule.max)) - subTotal;

		const maxInstallments = Math.min(installmentRule.max, Math.trunc((subTotal + totalShipping + maxInterest) / (installmentRule.minValue || 1)));

		for(let i = 1; i <= maxInstallments; i++)
		{
			var interest = 0;

			if(!installmentRule.installmentsInterest || installmentRule.installmentsInterest.length == 0)
			{
				if(i > installmentRule.free && installmentRule.interestRate)
				{
					if(!installmentRule.compositeInterest)
					{
						interest = (subTotal * installmentRule.interestRate) / 100;
					}
					else
					{
						interest = (subTotal * Math.pow(1 + (installmentRule.interestRate / 100), i)) - subTotal;
					}
					
					amountInstallments = subTotal + interest;
				}
			}
			else
			{
				var interestRate = installmentRule.installmentsInterest.find((element: any) =>
				{
					return element.installments == i;
				});

				if(interestRate)
				{
					interestRate = interestRate.interest;
				}
				else
				{
					interestRate = 0;
				}

				if(!installmentRule.compositeInterest)
				{
					interest = (subTotal * interestRate) / 100;
				}
				else
				{
					interest = (subTotal * Math.pow(1 + (interestRate / 100), i)) - subTotal;
				}

				amountInstallments = +Number(subTotal + interest).toFixed(2);
			}

			const installmentAmount = (amountInstallments + totalShipping) / i;
	
			installments.push({
				id		 : i + 'x',
				label	 : i + 'x - R$ ' + installmentAmount.toLocaleString('pt-BR', { minimumFractionDigits : 2, maximumFractionDigits : 2}) + (interest == 0 ? ' S/ Juros' : ''),
				value	 : i,
				interest : +Number(interest).toFixed(2),
			});
		}
	}

	if(installments.length == 0)
	{
		const installmentAmount = (subTotal + totalShipping);

		installments.push({
			id		 : '1x',
			label	 : '1x - R$ ' + installmentAmount.toLocaleString('pt-BR', { minimumFractionDigits : 2, maximumFractionDigits : 2}) + ' S/ Juros',
			value	 : 1,
			interest : 0,
		});
	}

	return installments;
}

export { 
	productPrice, 
	productPromotionalPrice, 
	productPromotionalPercentage,
	productRealPrice,
	priceInstallments,
	productInstallments,
	productDiscount,
	discount,
	calculateInstallments,
	percentageByPaymentMethod,
	hasPaymentMethod,
}

