import Types from "../type";
import { call } from "../util/call.api";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ANALYTICS_SETTING } from "../../setting/setting";
import { browserName, isDesktop, osName,  } from "react-device-detect";
import { getDomainFromReferrer } from "../util/util";
import { todayWeather } from "../weather/weather.api";
import { TDate } from "../model/TDate";
import { useCookies } from 'react-cookie'

class AnalyticsEvents 
{
	static events : any = [];
	static pid : any;
}

const registerEvent = (event:any, timeout=3000) => 
{
	if(!AnalyticsEvents.events) 
	{
	  	AnalyticsEvents.events = [];
	}

	// REMOVE DUPLICATES DOCUMENTS
	if(event.documents)
	{
		const data 		: any = {};
		const documents = []

		for(const item of event.documents)
		{
			if(!data[item.referencePath])
			{
				documents.push(item);
				data[item.referencePath] = true;
			}
		}

		event.documents = documents;
	}

	// REMOVE DUPLICATE KEYS
	if(event.keys)
	{
		const data : any = {};
		const keys = []

		for(const name of event.keys)
		{
			if(!data[name])
			{
				keys.push(name);
				data[name] = true;
			}
		}

		event.keys = keys;
	}

	AnalyticsEvents.events.push(event);

	if(AnalyticsEvents.pid)
	{
		clearTimeout(AnalyticsEvents.pid);
	}

	AnalyticsEvents.pid = setTimeout(() => 
	{		
		if(ANALYTICS_SETTING.status)
		{
			call(Types.PUSH_ANALYTICS_SERVER, ANALYTICS_SETTING.merge({
				events : AnalyticsEvents.events
			}));	
		}

		AnalyticsEvents.events = [];

	}, timeout);
};

// PAGE VIEWS
const pageViewsAnalytics = (seo:any) => 
{	
	registerEvent({
		action   : Types.PAGE_VIEWS_ACTION_ANALYTICS,
		keys     : [seo?.title],
	});	
}

// UNIQUE USER
const useUniqueUserAnalytics = () => 
{
	const [cookies, setCookie] = useCookies(['uniqueUserAnalytics']);

	useEffect(() => 
	{
		const expires = new Date()
    	expires.setTime(expires.getTime() + (30 * 60 * 1000));

		if(cookies.uniqueUserAnalytics != 'close') 
		{
			registerEvent({
				action : Types.UNIQUE_USER_ACTION_ANALYTICS,
				keys   : ['Usuário Único'],
			});
		}

		setCookie('uniqueUserAnalytics', 'close', { path: '/', expires });

	}, []);	
}

// DEVICE
const useDeviceAnalytics = () => 
{
	const [cookies, setCookie] = useCookies(['deviceAnalytics']);

	useEffect(() => 
	{
		const expires = new Date()
    	expires.setTime(expires.getTime() + (30 * 60 * 1000))

		if(cookies.deviceAnalytics != 'close') 
		{
			registerEvent({
				action   : Types.DEVICE_ACTION_ANALYTICS,
				keys     : [(isDesktop ? 'Desktop' : 'Mobile'), osName, browserName],
			});			
		}

		setCookie('deviceAnalytics', 'close', { path: '/', expires });

	}, []);	
}

// REFERRER
const useReferrerAnalytics = () => 
{
	const [cookies, setCookie] = useCookies(['referrerAnalytics']);

	useEffect(() => 
	{
		const expires = new Date()
    	expires.setTime(expires.getTime() + (30 * 60 * 1000))

		if(cookies.referrerAnalytics != 'close') 
		{
			const domain = getDomainFromReferrer();

			registerEvent({
				action   : Types.REFERRER_ACTION_ANALYTICS,
				keys     : [domain],
			});			
		}

		setCookie('referrerAnalytics', 'close', { path: '/', expires });

	}, []);	
}

// PERIODO DO DIA
const usePeriodsDaysAnalytics = () => 
{
	const [cookies, setCookie] = useCookies(['periodsDaysAnalytics']);
	
	useEffect(() => 
	{
		const expires = new Date()
    	expires.setTime(expires.getTime() + (30 * 60 * 1000))

		if(cookies.periodsDaysAnalytics != 'close') 
		{			
			const dayWeek  = new TDate().format('dddd');
			const hour     = parseInt(new TDate().format('HH'));
			let label      = '';
	
			if(hour >= 0 && hour < 6)
			{
				label = 'Madrugada';
			}
			else if(hour >= 6 && hour < 12)
			{
				label = 'Manahã';
			}
			else if(hour >= 12 && hour < 18)
			{
				label = 'Tarde';
			}
			else if(hour >= 18 && hour < 24)
			{
				label = 'Noite';
			}
	
			registerEvent({
				action   : Types.PERIODS_DAYS_ACTION_ANALYTICS,
				keys     : [label],
			});

			registerEvent({
				action   : Types.HOUR_DAY_ACTION_ANALYTICS,
				keys     : [hour],
			});

			registerEvent({
				action   : Types.DAY_WEEK_ACTION_ANALYTICS,
				keys     : [dayWeek],
			});
		}

		setCookie('periodsDaysAnalytics', 'close', { path: '/', expires });

	}, []);	
}

// CLIMA TEMPO
const useWeatherAnalytics = () => 
{
	const [cookies, setCookie] = useCookies(['weatherAnalytics']);

	useEffect(() => 
	{
		const expires = new Date()
		expires.setTime(expires.getTime() + (30 * 60 * 1000))

		if(cookies.weatherAnalytics != 'close') 
		{
			const call = async () =>
			{
				const result = await todayWeather();
	
				if(result.data && result.data.daily && result.data.daily.weather)
				{
					for(const item of result.data.daily.weather)
					{
						registerEvent({
							action   : Types.WEATHER_ACTION_ANALYTICS,
							keys     : [item.label],
						});
					}
				}							
			}		
	
			call();	
		}

		setCookie('weatherAnalytics', 'close', { path: '/', expires });

	}, []);	
}

// QUANDO O PRODUTO VEM DO BACK
const useProductClickAnalytics = (product:any) => 
{
	const { query, isReady, asPath } = useRouter();
	const events : any = [];
	
	// COLOR VARIANTES 
	useEffect(() => 
	{
		if(query.color)
		{
			registerEvent({
				action   : Types.VARIANT_CLICK_ACTION_ANALYTICS,
				keys     : ['level-1: ' + query.color],
				document : {
					referencePath : product.referencePath
				}
			});			
		}

	}, [query.color]);

	// SIZE VARIANTES 
	useEffect(() => 
	{
		if(query.size)
		{
			registerEvent({
				action   : Types.VARIANT_CLICK_ACTION_ANALYTICS,
				keys     : ['level-2: ' + query.size],
				document : {
					referencePath : product.referencePath
				}
			});
		}

	}, [query.size]);


	// ALL VARIANTES E SKU
	useEffect(() => 
	{
		if(query.color && query.size)
		{
			// ALL
			registerEvent({
				action   : Types.VARIANT_CLICK_ACTION_ANALYTICS,
				keys     : ['all: ' + query.color + '-' + query.size],
				document : {
					referencePath : product.referencePath
				}
			});

			// SKU
			registerEvent({
				action   : Types.SKU_CLICK_ACTION_ANALYTICS,
				keys     : [product.code + '-' + query.color + '-' + query.size],
				document : {
					name : product.name,
					code : product.code,
					document : {
						referencePath : product.referencePath
					}
				}
			});
		}

	}, [query.color, query.size]);

	// PRODUTOS
	useEffect(() => 
	{
		if(isReady)
		{
			registerEvent({
				action   : Types.PRODUCT_CLICK_ACTION_ANALYTICS,
				keys     : [product.code + ': ' + product.name],
				document : {
					referencePath : product.referencePath
				}
			});			
		}

	}, [isReady, asPath]);	
}

// QUANDO A CATEGORIA VEM DO BACK
const useCategoryClickAnalytics = (category:any) => 
{
	const { isReady, asPath } = useRouter();
	
	// PRODUTOS
	useEffect(() => 
	{
		if(isReady)
		{
			registerEvent({
				action   : Types.CATEGORY_CLICK_ACTION_ANALYTICS,
				keys     : [category?.name],
				document : {
					referencePath : category?.referencePath
				}
			});
		}		

	}, [isReady, asPath]);	
}

const useCollectionClickAnalytics = (page:any) => 
{
	const { isReady, asPath } = useRouter();
	
	// PRODUTOS
	useEffect(() => 
	{
		if(isReady)
		{
			registerEvent({
				action   : Types.COLLECTION_CLICK_ACTION_ANALYTICS,
				keys     : [page?.name],
				document : {
					referencePath : page?.referencePath
				}
			});
		}		

	}, [isReady, asPath]);	
}

const categoryClickAnalytics = (category:any) => 
{	
	if(category)
	{
		registerEvent({
			action   : Types.CATEGORY_CLICK_ACTION_ANALYTICS,
			keys     : [category.name],
			document : {
				referencePath : category.referencePath
			}
		});		
	}
}

const brandClickAnalytics = (brand:any) => 
{	
	if(brand)
	{
		registerEvent({
			action   : Types.BRAND_CLICK_ACTION_ANALYTICS,
			keys     : [brand.name],
			document : {
				referencePath : brand.referencePath
			}
		});		
	}
}

const productsViewsAnalytics = (documents:any) => 
{
	const items = [];
	const keys  = [];

	for(const item of documents)
	{
		items.push({
			referencePath : item.referencePath
		});
		keys.push(item.code + ': ' + item.name);
	}

	registerEvent({
		action    : Types.PRODUCT_VIEWS_ACTION_ANALYTICS,
		keys      : keys,
		documents : items
	});
}

const productsVariantViewsAnalytics = (documents:any) => 
{
	const items = [];
	const keys  = [];

	for(const item of documents)
	{
		if(item._parent)
		{
			items.push({
				referencePath : item._parent.referencePath
			});	
		}
		keys.push(item.code + ': ' + item.name);
	}

	registerEvent({
		action    : Types.PRODUCT_VIEWS_ACTION_ANALYTICS,
		keys      : keys,
		documents : items
	});
}

const userLoginAnalytics = (user:any) => 
{
	registerEvent({
		action : Types.USER_LOGIN_ACTION_ANALYTICS,
		keys   : [user.email],
	});	
}

const initCheckoutAnalytics = () => 
{
	registerEvent({
		action : Types.INIT_CHECKOUT_ACTION_ANALYTICS,
		keys   : [],
	});	
}

const couponCheckoutAnalytics = (coupon:string) => 
{
	registerEvent({
		action : Types.COUPON_CHECKOUT_ACTION_ANALYTICS,
		keys   : [coupon],
	});	
}

const addressCheckoutAnalytics = () => 
{
	registerEvent({
		action : Types.ADDRESS_CHECKOUT_ACTION_ANALYTICS,
		keys   : [],
	});	
}

const shippingCheckoutAnalytics = (name:string) => 
{
	registerEvent({
		action : Types.SHIPPING_CHECKOUT_ACTION_ANALYTICS,
		keys   : [name],
	});	
}

const paymentMethodCheckoutAnalytics = (name:string) => 
{
	registerEvent({
		action : Types.PAYMENT_METHOD_CHECKOUT_ACTION_ANALYTICS,
		keys   : [name],
	});	
}

const finishedCheckoutAnalytics = () => 
{
	registerEvent({
		action : Types.FINISHED_CHECKOUT_ACTION_ANALYTICS,
		keys   : [],
	}, 0);	
}

const addItemCartAnalytics = (product:any, variant:any, quantity:any) => 
{
	// PRODUTO
	registerEvent({
		action : Types.ADD_PRODUCT_CART_ACTION_ANALYTICS,
		keys   : [product.code + ': ' + product.name],
		document : {
			referencePath : product.referencePath
		}
	});	

	// SKU
	let sku = product.code;

	if(variant && variant.length > 0)
	{
		for(const item of variant)
		{
			if(item && item.value)
			{
				sku += '-' + item.value;
			}			
		}		
	}

	registerEvent({
		action : Types.ADD_SKU_CART_ACTION_ANALYTICS,
		keys   : [sku],
		document : {
			referencePath : product.referencePath
		}
	});	

	// VARIANT
	if(variant && variant.length > 0)
	{
		   let all = 'all: ';
		const keys = [];

		for(const key in variant)
		{
			const item = variant[key];		
			
			if(item && item.value)
			{
				keys.push('level-' + (parseInt(key) + 1) +  ': ' + item.value);
				all += item.value + (parseInt(key) == variant.length - 1 ? '' : '-');	
			}
		}

		keys.push(all);

		registerEvent({
			action : Types.ADD_VARIANT_CART_ACTION_ANALYTICS,
			keys   : keys,
			document : {
				referencePath : product.referencePath
			}
		});
	}
}

export { 
	pageViewsAnalytics,
	useProductClickAnalytics, 	
	useCategoryClickAnalytics,
	productsViewsAnalytics,
	userLoginAnalytics,
	addItemCartAnalytics,
	initCheckoutAnalytics,
	couponCheckoutAnalytics,
	addressCheckoutAnalytics,
	shippingCheckoutAnalytics,
	paymentMethodCheckoutAnalytics,
	finishedCheckoutAnalytics,
	categoryClickAnalytics,
	brandClickAnalytics,
	useCollectionClickAnalytics,
	usePeriodsDaysAnalytics,
	useWeatherAnalytics,
	productsVariantViewsAnalytics,
	useDeviceAnalytics,
	useReferrerAnalytics,
	useUniqueUserAnalytics,
}