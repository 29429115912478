import { THEME_SETTING } from "../../setting/setting";
import styles from "./styles.module.scss";
import Image from "next/image";

export const PaymentsMethods = () => {
  return (
    <section className={styles.paymentMethods}>
      {/* <h1>Formas de Pagamento</h1> */}
      <div className={styles.paymentsList}>
        <img
          width={40}
          height={32}
          src="/assets/icons/visa.svg"
          alt="Visa"
        />
        <img
          width={40}
          height={32}
          src="/assets/icons/mastercard.svg"
          alt="Master"
        />
        <img width={40} height={32} src="/assets/icons/elo.svg" alt="Elo" />
        <img width={40} height={32} src="/assets/icons/pix.svg" alt="Pix"/>
        <img
          width={40}
          height={32}
          src="/assets/icons/americanexpress.svg"
          alt="American Express"
        />
        <img
          width={40}
          height={32}
          src="/assets/icons/dinners.svg"
          alt="Dinners"
        />
        <img
          width={40}
          height={32}
          src="/assets/icons/hipercard.svg"
          alt="Hipercard"
        />
      </div>
      <p>{THEME_SETTING.paymentInfo}</p>
    </section>
  );
};
