/* PIPPA */
import Types from "../type";
import BaseModel from "./base.model";

const combineListVariant = (list:any, lists:any) =>
{
	const _lists = [];

	for(const item of list)
	{			
		for(const _list of lists)
		{				
			const __list = _list.concat([])
			__list.push(item);
			_lists.push(__list);
		}
	}

	return _lists;
}

const combineItemsVariant = (itemsVarint:any,) =>
{
	let allLists = [[]];

	if(!itemsVarint || itemsVarint.length == 0)
	{
		itemsVarint = Types.VARIANT_DEFAULT;
	}

	for(const list of itemsVarint)
	{
		if(list)
		{
			allLists = combineListVariant(list.items, allLists);
		}
	}

	return allLists;
}

const getPathListVariant = (list:Array<any>) =>
{
	let path = '';
	let i   = 0;

	for(const path2 in list)
	{
		if(list[path2])
		{
			path += list[path2].value + (i == (list.length - 1) ? '' : '-');			
		}			

		i++;
	}

	return path;
}
class Matrix extends BaseModel
{
	initialize()
	{		
		this.isDefault = true; // DEPOIS TROCAR POR data._default
		this.type 	   = Types.COMBINE_MATRIX;
		this._variant  = Types.VARIANT_DEFAULT;
	}
 
	populate(args:any)
	{
		/// PORQUE O VARIANTE VEM PRIMERIO QUE O TYPE, E PRECISAMOS DO TYPE PARA O VARIANT
		if(args && args.type)
		{
			this.type = args.type;
		}

		super.populate(args);		
	}

	combine(list:any, lists:any)
	{
		const _lists = [];

		for(const item of list)
		{			
			for(const _list of lists)
			{				
				const __list = _list.concat([])
				__list.push(item);
				_lists.push(__list);
			}
		}

		return _lists;
	}

	set variant(lists:any)
	{
		if(lists)
		{		
			let allLists : any = [];
			let listChange = false; // para não pegar lista fazia

			if(this.type.value == Types.COMBINE_MATRIX.value)
			{
				allLists = [[]];

				for(const list of lists)
				{
					if(list)
					{
						allLists   = this.combine(list.items, allLists);
						listChange = true;
	
						if(list.items.length > 0 && list.items[0].value !== '_default')
						{
							this.isDefault = false;
						}
					}
				}
			}
			else if(this.type.value == Types.SEPARATE_MATRIX.value)
			{
				for(const list of lists)
				{
					if(list)
					{
						listChange = true;

						for(const item of list.items)
						{
							allLists.push([item]);
						}
	
						if(list.items.length > 0 && list.items[0].value !== '_default')
						{
							this.isDefault = false;
						}
					}
				}
			}
			
			if(listChange)
			{
				this.items 	  = allLists;
				this._variant = lists;
			}

			// SEMPRE QUE ADICIONA VARIANTE NORMALIZAR
			this.data = this.normalizeData();
		}			
	}

	get variant()
	{
		return this._variant;
	}

	getVariantByLevel(level:number)
	{		
		let allLists : any = [[]];

		for(const key in this._variant)
		{
			if(this._variant[key])
			{
				if((level - 1) >= parseInt(key))
				{
					const _lists = [];

					for(const item of this._variant[key].items)
					{			
						for(const _list of allLists)
						{				
							const __list = _list.concat([])
							__list.push({ items : [item]});
							_lists.push(__list);
						}
					}

					allLists = _lists;
				}
				else
				{
					for(const item of allLists)
					{
						item.push({ items : this._variant[key].items});
					}
				}
			}
		}

		return allLists;
	}

	getPath(list:Array<any>)
	{
		let path = '';
		let i   = 0;

		for(const path2 in list)
		{
			if(list[path2])
			{
				path += list[path2].value + (i == (list.length - 1) ? '' : '-');			
			}			

			i++;
		}

		return path;
	}

	getValue(list:Array<any>, name?:any)
	{		
		//console.log(list, name, this.data);		
		
		if(this.data)
		{
			if(this.isDefault)
			{
				if(name)
				{
					return this.data._default[name];
				}
				else
				{
					return this.data._default;	
				}				
			}				
			else
			{
				const path  = this.getPath(list);
				  let value = this.data[path];

				if(value && name)
				{
					value = value[name];
				}

				return value;
			}		
		}

		return null;
	}

	getValueByListItems(list:Array<any>, name?:any)
	{		
		if(this.data)
		{
			if(this.isDefault)
			{
				return this.data._default;
			}				
			else
			{
				let allLists = [[]];

				for(const _list of list)
				{
					allLists = this.combine(_list.items, allLists);
				}
					
				const data : any = {};
	
				for(const list of allLists)
				{
					const path  = this.getPath(list);
					const value = this.data[path];

					if(value)
					{
						data[path] = value;	
					}					
				}
		
				return data;		
			}			
		}

		return null;
	}

	getValueByVariant(variant:any, name?:any)
	{
		let data : any = null;

		// POIS NO CASO DE QUERYSTRING TEMOS APENAS O VALOR	
		if(typeof variant == 'string')
		{
			variant = { value : variant};
		}

		if(this.isDefault)
		{
			return this.data;
		}

		for(const list of this.items)
		{
			for(const item of list)
			{
				if(item?.value == variant?.value)
				{
					if(!data)
					{
						data = {};
					}

					const path  = this.getPath(list);
					const value = this.getValue(list, name)					
					
					if(value)
					{
						data[path] = value;
					}					

					break;
				}
			}
		}
		
		return data;
	}

	getValueByListVariant(list:any, name?:any)
	{
		let data : any = null;

		if(this.isDefault || !list)
		{
			return this.data;
		}

		// REMOVE VAZIOS
		// NO CASO DE QUERYSTRING TEMOS APENAS O VALOR	
		let _list = [];

		for(const key in list)
		{
			if(list[key])
			{
				if(typeof list[key] == 'string' || typeof list[key] == 'number')
				{
					list[key] = {
						value : list[key]
					};
				}

				_list.push(list[key])
			}
		}		

		list = _list;

		let subList = this.items;

		for(const key in list)
		{
			const newSubList = [];

			for(const listItems of subList)
			{
				if(list[key] && listItems[key])
				{
					if(listItems[key].value == list[key].value)
					{
						newSubList.push(listItems);		
					}
				}				
			}

			subList = newSubList; 
		}		

		for(const list of subList)
		{
			if(!data)
			{
				data = {};
			}

			const path  = this.getPath(list);
			const value = this.getValue(list, name)					
			
			if(value)
			{
				data[path] = value;
			}
		}
		
		return data;
	}

	setValue(list:Array<any>, value:any)
    {
		const path = this.getPath(list);
		
		if(!this.data)
		{
			this.data = {};
		}

		this.data[path] = value;
	}

	normalizeData()
	{
		let data : any = {};

		if(this.data && this.data._default)
		{
			data 		  = this.data;
			this._variant = Types.VARIANT_DEFAULT;
		}
		else
		{			 
			// LIMPA O DATA DE ITEMS REMOVIDOS OU ATUALIZA
			for(const item of this.items)
			{
				const path  = this.getPath(item);
				const value = this.data ? this.data[path] : null;				
	
				// DATA
				if(value)
				{
					data[path] = value;
				}	
			}
		}		

		return this.data;
	}

	async getData()
	{
		return {
			data 	: this.data,
			variant : this.variant,
			type 	: this.type
		}
	}
}

export {
	combineListVariant,
	combineItemsVariant,
	getPathListVariant,
	Matrix,
}
