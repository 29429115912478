import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import styles from "./styles.module.scss";
import { randomString } from "../../core-nextv3/util/util";

export const MenuTypeMobile = ({ handleModal, item }: any) => 
{
    const [openSubMenu, setOpenSubMenu] = useState(false);

    const getMenu = (item: any) => {
        switch (item?.type?.value) {
            case 'path':
                return <>{item?._children?.length > 0 ? (
                    <li>
                       <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' ' + styles.subMenuNavigation} onClick={() => setOpenSubMenu(!openSubMenu)}>
                            {item.name}
                            <IoIosArrowForward />
                        </p>
                        {openSubMenu && <div className={(styles.subMenuChildren) + ' ' + (item.differentFont ? styles.differentFont : '')}>
                             {item?.addSeeAll && <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                                <a href={`${item.link}`} 
                                    onClick={() => { handleModal(false)}}>
                                    Ver Todos
                                </a>
                            </p>}
                            {item?._children?.map((child: any) => (
                                child.status && <div
                                key={randomString(8)}>
                                    {getMenu(child)}
                                </div>
                            ))}
                        </div>}                        
                    </li>
                ) : (
                    <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                        <a href={`${item.link}`} onClick={() => { handleModal(false) }}>
                            {item?.name}
                        </a>
                    </p>
                )}
            </>                
            case 'category':
                return <>{item?._children?.length > 0 ? (
                    <li>
                        <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                            <a className={styles.subMenuNavigation} onClick={() => setOpenSubMenu(!openSubMenu)}>
                                {item.name}
                                <IoIosArrowForward />
                            </a>
                        </p>
                        {openSubMenu && <div className={styles.subMenuChildren}>
                            {item?.addSeeAll && <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                                <a href={`/categoria/${item?.category?.slug}/`} 
                                    onClick={() => { handleModal(false)}}>
                                    Ver Todos
                                </a>
                            </p>}
                            {item?._children?.map((child: any) => (
                                child.status && <div
                                key={randomString(8)}>
                                    {getMenu(child)}
                                </div>
                            ))}                           
                        </div>}
                    </li>
                ) : (
                    <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                        <a href={`/categoria/${item?.category?.slug}/`} onClick={() => { handleModal(false) }}>
                            {item?.name}
                        </a>
                    </p>
                )}
            </>
            case 'collection':
                return <>{item?._children?.length > 0 ? (
                    <li>
                       <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' ' + styles.subMenuNavigation} onClick={() => setOpenSubMenu(!openSubMenu)}>
                            {item.name}
                            <IoIosArrowForward />
                        </p>
                        {openSubMenu && <div className={(styles.subMenuChildren) + ' ' + (item.differentFont ? styles.differentFont : '')}>
                             {item?.addSeeAll && <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                                <a href={`/colecao/${item?.collection?.slug}/`} 
                                    onClick={() => { handleModal(false)}}>
                                    Ver Todos
                                </a>
                            </p>}
                            {item?._children?.map((child: any) => (
                                child.status && <div
                                key={randomString(8)}>
                                    {getMenu(child)}
                                </div>
                            ))}
                        </div>}                        
                    </li>
                ) : (
                    <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                        <a href={`/colecao/${item?.collection?.slug}/`} onClick={() => { handleModal(false) }}>
                            {item?.name}
                        </a>
                    </p>
                )}
            </>
            default:
                return null; 
        }
    }   

    return (
        <div className={styles.menuTypeMobile}>
            {item.status && getMenu(item)}            
        </div>
    );
}