import styles from "./styles.module.scss";
import { BsWhatsapp } from "react-icons/bs";
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { removePhoneMask } from "../../core-nextv3/util/util";

export const WhatsAppIcon = ({ account }: any) => {
  return (
    <div
      className={styles.whatsAppIcon}
      onClick={() =>
        {
          tagManager4.registerEvent("contact", "whatsapp-button", "Contato", 0, null);
          window.open(
            `https://api.whatsapp.com/send?phone=${removePhoneMask(account?.whatsapp)}&text=Olá, gostaria de tirar algumas duvidas.`,
            "_blank"
          )
        }
      }
    >
      <BsWhatsapp />
    </div>
  );
};
