import Types from "../type";
import { ISetting } from "../util/setting";
import { call } from "../util/call.api";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { CART_SETTING } from "../../setting/setting";
import { useCore } from "../core/core";

const useGetCart = (setting:ISetting, onSuccess:any) =>  
{
	const router 		  	   = useRouter();
	const { setIsLoadingCart } = useCore();

	useEffect(() => 
	{
		setIsLoadingCart(false);
		
		call(Types.GET_CART_SERVER, setting).then((result) => 
		{
				onSuccess(result.data);

				setIsLoadingCart(true);			
		});
		
	}, [router.asPath]);	
}

const useMergeCart = (setting:ISetting, onSuccess:any) =>  
{
	const router   = useRouter();
	const { cart } = useCore();

	useEffect(() => 
	{
		if(router.query.document && cart)
		{
			call(Types.MERGE_CART_SERVER, setting.merge(
			{
				document : {
					referencePath : router.query.document
				}
			})).then((result) => 
			{
				onSuccess(result.data);
			});
		}

	}, [router.query, cart]);
}

const useSetStoreCart = (setting:ISetting, onSuccess?:any) => 
{
	const router = useRouter();	
	
	useEffect(() => 
	{
		call(Types.SET_STORE_SERVER, setting).then((result) => 
		{
			if(onSuccess)
			{
				onSuccess(result.data)
			}			
		});	
	}, [router.asPath]);
}

const useSetCouponCart = (setting:ISetting, onSuccess?:any) => 
{
	const router = useRouter();	
	
	useEffect(() => 
	{
		call(Types.SET_COUPON_CART, setting).then((result) => 
		{
			if(onSuccess)
			{
				onSuccess(result.data)
			}			
		});	
	}, [router.asPath]);
}

const useSetSellerCart = (setting:ISetting, onSuccess?:any) => 
{
	const router = useRouter();	
	
	useEffect(() => 
	{
		call(Types.SET_SELLER_CART, setting).then((result) => 
		{
			if(onSuccess)
			{
				onSuccess(result.data)
			}			
		});	
	}, [router.asPath]);
}

const getCart = async (setting: ISetting) => 
{
	const result = await call(Types.GET_CART_SERVER, setting);
	return result;
};

const mergeCart = async (setting: ISetting) => 
{
	const result = await call(Types.MERGE_CART_SERVER, setting);
	return result;
};

const mergeAllCart = async (setting: ISetting) => 
{
	const result = await call(Types.MERGE_ALL_CART_SERVER, setting);
	return result;
};

const validateCart = async (setting: ISetting) => 
{
	const result = await call(Types.VALIDATE_CART_SERVER, setting);
	return result;
};

const setInstallmentCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_INSTALLMENT_CART_SERVER, setting);
	
	return result;
}

const setInstallmentMethods = async (setting:ISetting) =>
{
	const result = await call(Types.SET_INSTALLMENT_METHODS_SERVER, setting);

	return result;
}

const checkoutCart = async (setting:ISetting) => 
{
	const result = await call(Types.CHECKOUT_CART_SERVER, setting);
	
	return result;
}

const setCouponCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_COUPON_CART, setting);
	
	return result;
}

const delCouponCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_COUPON_CART, setting);
	
	return result;
}

const setDiscountClientCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_DISCOUNT_CLIENT_CART, setting);
	
	return result;
}

const delDiscountClientCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_DISCOUNT_CLIENT_CART, setting);
	
	return result;
}

const setAddressCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ADDRESS_CART_SERVER, setting);
	
	return result;
}

const setShippingMethodCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_SHIPPING_METHOD_CART_SERVER, setting);
	
	return result;
}

const setPaymentMethodCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_PAYMENT_METHOD_CART_SERVER, setting);
	
	return result;
}

const setCreditCardCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_CREDITCARD_CART_SERVER, setting);
	
	return result;
}

const setAttachmentCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ATTACHMENT_CART_SERVER, setting);
	
	return result;
}

const clearCart = async (setting:ISetting) => 
{
	const result = await call(Types.CLEAR_CART_SERVER, setting);
	
	return result;
}

const setItemCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ITEM_CART_SERVER, setting);
	
	return result;
}

const setPackaging = async (setting:ISetting) => 
{
	const result = await call(Types.SET_PACKAGING_CART_SERVER, setting);
	
	return result;
}

const setGiftCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_GIFT_CART_SERVER, setting);
	
	return result;
}

const setItemsCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ITEMS_CART_SERVER, setting);
	
	return result;
}

const setClientCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_CLIENT_CART_SERVER, setting);
	
	return result;
}

const setReferrerCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_REFERRER_CART_SERVER, setting);
	
	return result;
}

const setUTMCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_UTM_CART_SERVER, setting);
	
	return result;
}

const setDiscountCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_DISCOUNT_CART_SERVER, setting);
	
	return result;
}

const setSellerCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_SELLER_CART_SERVER, setting);
	
	return result;
}

const delItemCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_ITEM_CART_SERVER, setting);
	
	return result;
}

const delGiftCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_GIFT_CART_SERVER, setting);
	
	return result;
}

const delItemsByStoreCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_ITEMS_BY_STORE_CART_SERVER, setting);
	
	return result;
}

const delItemsByProductCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_ITEMS_BY_PRODUCT_CART_SERVER, setting);
	
	return result;
}

const setAddressReferenceCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ADDRESS_REFERENCE_CART_SERVER, setting);
	
	return result;
}

const setCreditCardReferenceCart = async (setting: ISetting) => {
	const result = await call(Types.SET_CREDITCARD_REFERENCE_CART_SERVER, setting);
  
	return result;
};

const calculateZipCodeCart = async (setting: ISetting) => {
	const result = await call(Types.CALCULATE_ZIP_CODE_SERVER, setting);
  
	return result;
};


export { 
	getCart,
	useGetCart,
	mergeCart,
	useSetStoreCart, 
	setAddressReferenceCart,	
	setCreditCardReferenceCart, 
	delItemsByProductCart,
	setItemsCart, 
	delItemCart, 
	checkoutCart, 
	clearCart, 
	setAddressCart, 
	setInstallmentCart,
	setInstallmentMethods, 
	setItemCart,
	setShippingMethodCart, 
	setAttachmentCart, 
	setPaymentMethodCart, 
	setCreditCardCart, 
	setCouponCart, 
	setDiscountClientCart,
	delDiscountClientCart,
	validateCart,
	calculateZipCodeCart,
	delItemsByStoreCart,
	setSellerCart,
	setClientCart,
	setDiscountCart,
	delCouponCart,
	useMergeCart,
	mergeAllCart,
	setGiftCart,
	delGiftCart,
	setReferrerCart,
	useSetCouponCart,
	useSetSellerCart,
	setPackaging,
	setUTMCart,
}