import { THEME_SETTING } from "../../setting/setting";
import { Certificates } from "./Certificates";
import { PaymentsMethods } from "./PaymentMethods";
import styles from "./styles.module.scss";

export const Bottom = () => {
  return (
    <section className={styles.bottom}>
        <PaymentsMethods />
        <div dangerouslySetInnerHTML={{ __html: THEME_SETTING.footerInfo }}></div>
        <Certificates />
    </section>
  );
};
