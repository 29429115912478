import { firstProductImage } from "../product/product.util";
import { stripHtmlTags } from "./util";

export interface ISEO{
	merge 		 : Function,
	mergeAccount : Function,
	mergePage 	 : Function,
	mergeProduct : Function,
}

const SEO = (data={}):ISEO => 
{
    const obj = Object.assign(
	{
		merge : function(args:any)
		{
			let title 	    = '';
			let description = '';
			let images      = [];

			if(args?.title)
			{
				title = args.title;
			}

			if(args?.description)
			{
				description = stripHtmlTags(args.description);
			}

			if(args?.logo)
			{
				images = [{
					url : args.logo._url,
				}]
			}

			const data : any = {
				title 		: title,
				description : description,
				openGraph : {
					title 		: title,
					description : description,
				}
			};

			return SEO(Object.assign({}, this, data));
		},
		mergeAccount : function(account:any)
		{
			let title 	    = '';
			let description = '';
			let images      = [];

			if(account)
			{
				if(account.seo)
				{
					title 	   	= account.seo.title || '';
					description = account.seo.description || '';
				}
				else
				{
					if(account.name)
					{
						title = account.name;
					}
		
					if(account?.description)
					{
						description = stripHtmlTags(account.description);
					}
				}		
	
				if(account.logo) 
				{
					images = [{
						url : account.logo._url,
					}]
				}
			}			

			const data : any = {
				title 		: title,
				description : description,
				openGraph : {
					title 		: title,
					description : description,
				}
			};

			return SEO(Object.assign({}, this, data));
		},
		mergePage : function(account:any, page:any)
		{
			let title 	    = '';
			let description = '';
			let images      = [];

			if(page.seo)
			{
				title 	   	= page.seo.title;
				description = stripHtmlTags(page.seo.description);
			}
			else
			{
				if(page?.name)
				{
					title = page.name;
				}
	
				if(page?.description)
				{
					description = stripHtmlTags(page.description);
				}
			}			

			if(account?.logo) 
			{
				images = [{
					url : account.logo._url,
				}]
			}

			if(account?.name)
			{
				title += ' | ' + account?.name;
			}

			const data : any = {
				title 		: title,
				description : description,
				openGraph : {
					title 		: title,
					description : description,
				}
			};

			return SEO(Object.assign({}, this, data));
		},
		mergeProduct : function(account:any, product:any)
		{
			let title 	    = '';
			let description = '';
			let keywords    = '';
			let images      = [];

			if(product.seo)
			{
				title 	   	= product.seo.title;
				description = stripHtmlTags(product.seo.description);
			}
			else
			{
				if(product?.name)
				{
					title = product.name;
				}
	
				if(product?.description)
				{
					description = stripHtmlTags(product.description);
				}
	
				if(product?.keywords)
				{
					keywords = stripHtmlTags(product.keywords);
				}			
	
				if(product?.images)
				{
					const image = firstProductImage(product);
					
					if(image && image._1024x1024)
					{
						images = [{
							url : image._1024x1024
						}]	
					}
				}
			}
			
			if(account?.name)
			{
				title += ' | ' + account?.name;
			}
			
			const data : any = {
				title 		: title,
				keywords    : keywords,
				description : description,				
				openGraph : {
					title 		: title,
					keywords    : keywords,
					description : description,
				}
			};

			return SEO(Object.assign({}, this, data));
		},		
	}, data);

	Object.defineProperty(obj, "merge", 	   { writable: true, enumerable: false, configurable: true });
	Object.defineProperty(obj, "mergeAccount", { writable: true, enumerable: false, configurable: true });
	Object.defineProperty(obj, "mergePage",	   { writable: true, enumerable: false, configurable: true });
	Object.defineProperty(obj, "mergeProduct", { writable: true, enumerable: false, configurable: true });

	return obj;
};

export { SEO }