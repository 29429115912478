import "../styles/global.scss";
import '@djthoms/pretty-checkbox';
import 'react-aspect-ratio/aspect-ratio.css'

import type { AppProps, NextWebVitalsMetric } from "next/app";
import { AppPage } from "../varejo-nextv3/AppPage";

// export function reportWebVitals(metric: NextWebVitalsMetric) 
// {
// 	switch (metric.name) 
// 	{
// 		case 'TTFB':
// 			break	
// 		case 'FCP':			
// 			break
// 		case 'LCP':						
// 			break
// 		case 'FID':
// 			break
// 		case 'CLS':
// 			break	
// 		case 'INP':
// 			break
// 		default:
// 			break
// 	}
// }

function MyApp({ Component, pageProps }: AppProps) 
{
	return (
		<AppPage Component={Component} pageProps={pageProps} />	
	);
}
export default MyApp;
