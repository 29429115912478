import styles from "./styles.module.scss";
import { useState } from "react";
import toast from "react-hot-toast";
import { ImageSet } from "../ImageSet";
import { AnimatedLoading } from "../AnimatedLoading";
import { CART_SETTING, THEME_SETTING } from "../../setting/setting";
import { setGiftCart } from "../../core-nextv3/cart/cart.api";
import { firstProductImage, validateListVariant, } from "../../core-nextv3/product/product.util";
import { VariantSelector } from "../VariantSelector";
import { useCore } from "../../core-nextv3/core/core";
import { hasStockProduct } from "@/core-nextv3/stock/stock.util";

export const ItemGifts = ({ page, product }: any) => 
{
    const [loadingAddToCart, setLoadingAddToCart] = useState<any>(false);
    const [listVariant,      setListVariant]      = useState<any>();
    const { setCart }                             = useCore();

    const handleAddToCart = async () => 
    {
        if(!validateListVariant(product, listVariant))
        {
            toast.error('Seleciona a variante do produto',
            {
                duration: 2000,
            });

            return;
        }

        setLoadingAddToCart(true);

        const newData = {
            giftPage : {
                referencePath : page.referencePath
            },
            data : {
                product : {
                    referencePath: product.referencePath,
                },
                variant  : listVariant,
                quantity : 1,
            },
        };

        const result = await setGiftCart(CART_SETTING.merge(newData));

        setLoadingAddToCart(false);

        if(result.status == false) 
        {
            return toast.error(result.error,
            {
                duration: 2000,
            });
        }

        setCart(result.data);
        
        toast.success("Brinde adiconado com sucesso!");        
    };

    async function handleChangeVariant(listVariant:any)
    {
        setListVariant(listVariant);
    } 

    return (
        <>
          {hasStockProduct(product) && <div key={product?.id} className={styles.item}>
              <div className={styles.product}>
                <div className={styles.img}>
                  <ImageSet width={480} image={firstProductImage(product)} aspectRatio={THEME_SETTING.aspectRatio}/>
                </div>
                <p className={styles.productName}>{product?.name}</p>
              </div>
              <VariantSelector product={product}
                               changeVariant={(event:any) => { handleChangeVariant(event)}}
                               disabledQuantity={true}
                               disabledAddCart={true}/>                      
              <div className={styles.actions}>
                  <button 
                    type="button"
                    className="block"
                    onClick={() => handleAddToCart()}
                  >
                    {loadingAddToCart ? <AnimatedLoading /> : "Adicionar Brinde"}
                  </button>
                </div>
            </div>}
        </>
    );
};
