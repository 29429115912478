import styles from "./styles.module.scss";
import { useState } from "react";
import { quantityStockByListVariant } from "../../../core-nextv3/stock/stock.util";

export const ItemQuantityTwo = ({ product, variants, changeQuantity, disabledLabel=false, value=1, item, getStockStyle }: any) => 
{
    // console.log("vçlksdnvblksadfnbvkl", getStockStyle(product, item, variants.length))
    const [quantity, setQuantity] = useState(value);
    
    function handleIncrementProductQuantity() 
    {
        if(quantityStockByListVariant(product, variants) > quantity) 
        {
            setQuantity(quantity + 1);
            changeQuantity(variants, quantity + 1);
        }
    }

	function handleDecrementProductQuantity() 
    {
		if(quantity > 0) 
        {
			setQuantity(quantity - 1);
            changeQuantity(variants, quantity - 1);
		}
	}

    return(
        <div className={styles.productQuantity}>
            {!disabledLabel && <span>Quantidade</span>}
            <div className={styles.inputQuantity + ' ' + styles[getStockStyle(product, item, variants.length)]}>
            {/* <div className={styles.inputQuantity}> */}
                <span onClick={handleIncrementProductQuantity}>+</span>
                <span className={styles.value}>{quantity}</span>
                <span onClick={handleDecrementProductQuantity}>-</span>
            </div>                          
        </div>
    );
}