export const disableReactDevTools = (): void => 
{
    const noop = (): void => undefined;
    const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;
  
    if(process.env.NODE_ENV == "production") 
    {
        if (typeof DEV_TOOLS === 'object') {
          for (const [key, value] of Object.entries(DEV_TOOLS)) {
              DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
          }
        }

        console.log = function no_console() {};
    }  

    const insertListener = function(event:any)
    {
        if(event.animationName == "nodeInserted") 
        {
            //console.warn("Another node has been inserted! ", event, event.target);
        }
    }

    document.addEventListener("animationstart", insertListener, false); // standard + firefox
    document.addEventListener("MSAnimationStart", insertListener, false); // IE
    document.addEventListener("webkitAnimationStart", insertListener, false); // Chrome + Safari    
};