import styles from './styles.module.scss'
import { ItemGifts } from './item'
import { ModalGifts } from './ModalGifts'
import { GiPresent } from 'react-icons/gi'
import { useEffect, useState } from 'react'
import { innerHTML } from '../../core-nextv3/util/util'
import { currencyMask } from '../../core-nextv3/util/mask'
import { ProductItemSkeletonCart } from '../ProductItemSkeletonCart'
import { useCore } from '../../core-nextv3/core/core'
import { useTranslation } from 'next-i18next'

export const Gifts = ({ page }: any) => 
{
  const [hasProducts, setHasProducts] = useState(false)
  const [openModalGifts, setOpenModalGifts] = useState(false)
  const { isLoadingCart, cart } = useCore()
  const { t } = useTranslation();

  useEffect(() => {
    setHasProducts(false)

    if (cart && cart.items?.length > 0) {
      // POIS PODE CONTER APENAS O BRIND
      for (const item of cart.items) {
        if (!item.gift) {
          setHasProducts(true)
          break
        }
      }
    }
  }, [cart])

  return (
    <>
      {hasProducts && (
        <div className={styles.gifts}>
          {/* <h2>Tem um brinde disponível para você!</h2> */}
          {!isLoadingCart ? (
            <>
              {Array.from({ length: 3 }).map((product, index) => (
                <ProductItemSkeletonCart key={`productSkeleton-${index}`} />
              ))}
            </>
          ) : (
            <>
              {cart?.totalItems < page?.value ? (
                <div className={styles.card}>
                  <div className={styles.cardBody}>
                      <div className={styles.box}>
                        <GiPresent />                        
                      </div>
                      <div className={styles.message}>
                          <h1>{page?.name}</h1>
                          <h4>
                            {t('Compras acima de')} R$ {currencyMask(page?.value)}
                          </h4>
                          <p>
                            Faltam apenas{' '}
                            <strong>
                              {currencyMask(page?.value - cart?.totalItems)}
                            </strong>{' '}
                            para você ganhar!
                          </p>
                      </div>                                          
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <div className={styles.box}>
                        <GiPresent />                        
                      </div>                      
                      <div className={styles.message}>
                        <h1>{page?.name}</h1>
                        <div
                            dangerouslySetInnerHTML={innerHTML(
                              page?.description
                            )}
                            className={styles.description}
                          ></div>
                          <button
                            className='block'
                            onClick={() => setOpenModalGifts(true)}
                          >
                            Você Ganhou
                          </button>
                      </div>
                    </div>
                  </div>
                  <>
                    {/* <div className={styles.body}>
                        {page?.products?.map((product:any, index:number) => (
                            <ItemGifts page={page} product={product} key={index}/>
                        ))}
                      </div> */}
                  </>
                </>
              )}
            </>
          )}
        </div>
      )}

      {openModalGifts && (
        <ModalGifts page={page} closeModalGifts={setOpenModalGifts} />
      )}
    </>
  )
}
