import styles from "./styles.module.scss";
import { useState } from "react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { GrFormClose } from "react-icons/gr";
import { searchAnimation } from "../animation";
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { removeAccents } from "../../core-nextv3/util/util";
import { useCore } from "../../core-nextv3/core/core";

export const SearchBar = ({ setSearchBar }: any) => 
{
    const router = useRouter();
    const [inputValue, setInputValue] = useState("");
    const {user} = useCore();

    return (
        <motion.div
            className={styles.searchBar}
            variants={searchAnimation}
            initial="hidden"
            animate="show"
            exit="exit"
        >
            <div className={styles.content}>
                <GrFormClose onClick={() => setSearchBar(false)} />
                <input onChange={(value) => setInputValue(value.target.value)} type="search" placeholder="Pesquisar" onKeyUp={(e) => {
                    if(e.key == "Enter")
                    {
                        setSearchBar(false);
                        tagManager4.registerEvent("click:search", "search", inputValue.trim(), 0, user);
                        router.push(`/produtos/?term=${removeAccents(inputValue).toLowerCase()}`);
                        return true;
                    }
                    return false;
                }} />
            </div>
        </motion.div>
    );
};
