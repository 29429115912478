import { Matrix } from "../model/matrix"

const productVideos = (product: any, list:any=null, videoField='videos') =>
{ 		
	if(product && product[videoField])
	{
		const videosTable = new Matrix(product[videoField]);
		const data       = videosTable.getValueByListVariant(list);	
		
		for(const key in data)
		{
			if(data[key] && data[key].video)
			{				
				return [data[key].video];
			}
		}			
	}

	return;
}

const hasProductVideo = (product: any, list:any=null, videoField='videos') =>
{ 	
	if(product && product[videoField])
	{
		const videosTable = new Matrix(product[videoField]);
		const data       = videosTable.getValueByListVariant(list);		

		for(const key in data)
		{
			if(data[key] && data[key].video)
			{	
				return true;
			}
		}			
	}

	return false;
}

const hasProductThumbVideo = (product: any, list:any=null, videoField='videos') =>
{ 	
	if(product && product[videoField])
	{
		const videosTable = new Matrix(product[videoField]);
		const data       = videosTable.getValueByListVariant(list);

		for(const key in data)
		{
			if(data[key] && data[key].video && data[key].video.thumb)
			{	
				return true;
			}
		}			
	}

	return false;
}

const getProductVideo = (product: any, list:any=null, videoField='videos') =>
{ 	
	if(product && product[videoField])
	{
		const videosTable = new Matrix(product[videoField]);
		const data       = videosTable.getValueByListVariant(list);

		for(const key in data)
		{
			if(data[key] && data[key].video)
			{	
				return data[key].video;
			}
		}			
	}

	return;
}

export { 
	productVideos,	
	hasProductVideo,
	getProductVideo,
	hasProductThumbVideo
}


