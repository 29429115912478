import { setting } from "../core-nextv3/util/setting";

const platform = {
	id    : "4Dy6elW7",
	label : "E-commerce",
	value : "ecommerce"
}

const THEME_SETTING = {
	disabledFeatures : false,
	disabledHeaderInline : true,
	pixDiscount : 18,
	disabledDisplayColorVatiant : true,
	disabledHeaderSticke : true,
	//aspectRatio : 683 / 1024,	
	widthBannerMain : 1600,
	heightBannerMain : 678,
	widthProductThumb : 683,
	heightProductThumb : 1024,
	imageCDN 				 : true,
	disabledFotoCategoryMobile : false,
	disabledInformationPaymenteCart : false,
	disabledCarouselPhotosSmall : true,
	filterPlatform						  : true,
	disabledShipping : false,
	productPath : '/produto/',
	maxParcel : 6,
	minValue : 50,
	revalidate : 60 * 30,
	footerInfo : '<p>© 2022 Todos os direitos reservados à LINDABELLA</p><p>CNPJ: 14.791.077/0001-31</p><p>R. dos Italianos, 271 - Bom Retiro, São Paulo - SP, 01131-000</p>',
	sacInfo1   : '<p>Atendimento sobre ao seu pedido ou devolução.</p><p>Email: sac@mariadechita.com.br<br/>Whatsapp: (11) 97076-4835</p><p>Segunda à Sexta das 08h00 às 16h00</p>',
	sacInfo2   : '<p>Personal Shopper</p><p>Atendimento sobre os nossos produtos, dicas de looks e ajuda para comprar na e-store.</p><p>De Segunda à Sexta, das 08h00 às 16h00</p>',
	cookieConsent : 'Olá cliente Maria de Chita, utilizamos cookies com fins analíticos e para mostrar anúncios relacionados a suas preferências. Para mais informações visite a nossa <a href="/loja/politica-de-privacidade" style={{ fontSize: "14px" }}>Política de Privacidade</a>',
	//noteShipping : 'COMPRAS REALIZADAS ENTRE OS DIAS 20/12/203 A 09/01/2024 SERÃO ENVIADAS A PARTIR DO DIA 10/01/2024',
};

const parseSizeVariant = (label:string) =>
{
	return label;
};

// MENU HEADER
const MENU_HEADER_STATIC = [{
	label : 'Como Comprar',
	url   : '/loja/como-comprar',
},
{
	label : 'Dúvidas ?',
	url   : '/loja/trocas-e-devolucoes',
}]

// MENU FOOTER
const MENU_FOOTER_STATIC = [{
	label : 'Sobre',	
	children : [
	{
		label : 'Sobre Nós',
		url   : '/loja/nossa-historia',
	},
	{
		label : 'Nossas Avaliações',
		url   : '/avaliacoes/',
	}]
},
{
	label : 'Ajuda',
	children : [{
		label : 'Política de Privacidade',
		url   : '/loja/politica-de-privacidade',
	},
	{
		label : 'FAQ',
		url   : '/loja/duvidas',
	},
	{
		label : 'Trocas e Devoluções',
		url   : '/loja/trocas-e-devolucoes',
	},
	{
		label : 'Prazo e Entrega',
		url   : '/loja/prazo-e-entrega',
	}]
}];

// ACCOUNT
const ACCOUNT_SETTING = setting({
	accid : "default",
	appid: "accounts",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/accounts/grids/BkjMWCLOWO35yX0mOGq0",
	},
});

// CATEGORY
const CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/6diDjwiaSnSRWAsxwiL8",
	},
});

const SLUG_CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/GpvRpQGThYz06yvOimNF",
	},
});

// MENU
const MENU_SETTING = setting({
	accid: "default",
	appid: "menuEcom",
	colid: "documentsMenu",
	platform: platform,
	orderBy: "order",
	map: true,
	mapItems: {
	  	referencePath: "default/menuEcom/grids/7liMJ6662be59vfDjhX9",
	},
});

// COLLECTION
const COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/lGIknreRoG7pKFfLX2Sh",
	},
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

const SLUG_COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/qlwNvVYDewjYU36RMmTR",
	},
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

//PAGE
const PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/iBqwfjj1mYhVKHRnUuTn",
	},
});

//PAGE DE TROCA
const TROCA_PAGE_SETTING = PAGE_SETTING.merge({
	document: {
		referencePath: "default/page/documents/OrKWT7E6Jl9eUJhZRl1x",
	},
});

//BANNERS TOP PAGES
const BANNERS_TOP_PAGE_SETTING = PAGE_SETTING.merge({
	document: {
		referencePath: "default/page/documents/3fjdVepCHQpsxfYcV4H6",
	},
	mapItems : {
		referencePath: "default/page/grids/cPFQEgIKxIitQ6UGFsgL",
	},	
});

//HOME PAGE
const HOME_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/43EE2urGfxEoQa5nkxXb",
	},
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	id : "yUD8uh6SE4AnuPAe7gRX",
});

//ORDER
const ORDER_SETTING = setting({
	accid: "default",
	appid: "financial",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	form: {
        referencePath: "default/financial/forms/yBwJpLgkPRGiJcjVlz4r",
	},
	mapItems: {
		referencePath: "default/financial/grids/Pxn8ESNxcjMqviSVw7KN",
	},
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

//COLOR VARIANT
const COLOR_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/XRN1sB9yiQe3VMQVcwgA",
	},
	document : {
		referencePath: "default/variant/documents/Z7k8hiv6kmXnljSHNQZ8",		
	}
});

//SIZE VARIANT
const SIZE_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/XRN1sB9yiQe3VMQVcwgA",
	},
	document : {
		referencePath: "default/variant/documents/P2N4ByyklQ7kYUR2uCJn",		
	}
});

//FULL PRODUCT
const FULL_PRODUCT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	mapItems: {
		referencePath: "default/product/grids/ZUnonLswnK67KE4lnDmg",
	},
});

//PARTIAL PRODUCT
const PARTIAL_PRODUCT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	mapItems: {
		referencePath: "default/product/grids/518g8n8NTE70SfNShbVP",
	},
});

const PARTIAL_PRODUCT_VARIANT_SETTING = setting({
	accid: "default",
	appid: "productsVariant",
	colid: "documentsProductsVariant",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	mapItems: {
		referencePath: "default/productsVariant/grids/ug8QfSPPgpAVu47jaXv2",
	},
});

//SLUG PRODUCT
const SLUG_PRODUCT_SETTING = setting({
	accid : "default",
	appid : "product",
	colid : "documents",
	level : 1,
	map : true,
	asc : false,
	orderBy : "postdate",
	mapItems : {
		referencePath: "default/product/grids/juZ7wBolgtmJsMjp7Tj3",
	},
});

//CART
const CART_SETTING = setting({
	accid: "default",
	appid: "cart",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/cart/forms/yi8sxDEbExlshNIXREV3",
	},
	mapItems: {
		referencePath: "default/cart/grids/znhBLyFoQYdft9hbYq48",
	},
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
}); 

// FAVORITE
const FAVORITE_SETTING = setting({
	accid: "default",
	appid: "favorite",
	colid: "documents",
	skuImage:true,
	platform: platform,
	orderBy : 'postdate',
	asc : false,
	form: {
	  	referencePath: "default/favorite/forms/Q347HZI0dUmETqypadyE",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/favorite/grids/sQICPapMqxKyT4IgMGQx",
	},		
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},		
});

// AUTH
const AUTH_SETTING = setting({
	accid: "default",
	appid: "users",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/users/forms/5BMnB6DwQDHZ0ir8zatM",
	},
	map: true,
	mapItems: {
	  	referencePath: "default/users/grids/mB1DsizFim0Mfw55VhXd",
	},	
});

// ERRORS
const ERROS_SETTING = setting({
	accid: "default",
	appid: "erros",
	colid: "documentsErros",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/erros/forms/MgCOjsU7KwHu76BHLslV",
	},
	mapItems: {
		referencePath: "default/erros/grids/V8RcqixjPjxQUL7nZJdk",
	},
});

// OPTION
const OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/ilEcDBizap1j0WVZJwES",
	},	
});

// PAYMENT METHODS OPTION
const PAYMENT_METHODS_OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	document: {
		referencePath: "default/option/documents/kdEtgv8BEwVRju3W5gmD",
  	},
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/ilEcDBizap1j0WVZJwES",
	},	
});

// BANNER
const BANNER_SETTING = setting({
	accid: "default",
	appid: "banner",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "order",
	mapItems: {
	  	referencePath: "default/banner/grids/tbdlFvLpACmtWj9u1dNh",
	},
});

// TRACK
const TRACK_SETTING = setting({
	accid: "default",
	appid: "shippings",
	colid: "documentsShippings",
	platform: platform,
	shipping : {
		referencePath : 'default/shippings/documentsShippings/yexpmlDkm4kB6AWKDfgB'
	},
	mapItems: {
		referencePath: "default/accounts/grids/BkjMWCLOWO35yX0mOGq0",
	}
});

// ANALYTICS
const COLOR_TABLE_SETTING = setting({
	accid: "default",
	appid: "colorTable",
	colid: "documents",
	platform: platform,
	document: {
	  	referencePath: "default/colorTable/forms/XG5FEgOHyLiokbUjKpyQ",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/colorTable/grids/6XNlo5Be7vxNQxpTbDmM",
	},		
});

// ANALYTICS
const ANALYTICS_SETTING = setting({
	accid: "default",
	appid: "analytics",
	colid: "documents",
	platform: platform,
	form: {
	  	referencePath: "default/analytics/forms/Ug7kc4pRJaVLiZFouS96",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/analytics/grids/dFcRzAsbqUnF1WgDGS9T",
	},		
});

//REORDER
const REORDER_SETTING = setting({
	accid: "default",
	appid: "reorder",
	colid: "documentsReorder",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	reorderAmount : 1,
	ownerField : 'client',
	form: {
        referencePath: "default/reorder/forms/Sl5hcw9OImFLyQcw1zIb",
	},
	mapItems: {
		referencePath: "default/reorder/grids/3DC1OVXOs2gNgPn5ELQ5",
	}
});

//TOPHEADER
const TOP_HEADER_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/UPXhyQOe6mNag8qn1yUZ",
	},
});

const REORDER_TYPE_OPTION = OPTION_SETTING.merge({
	id : 'dBiqu3z0UxGgIKTDk83F'
})


//TRACK BACK
const TRACK_BACK_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/428EiUP5jzCZGFPjqxIm",
	},
	document: {
		referencePath: "default/page/documents/HZ7sAd5Gpx08ItKNyRtM"
	}
});

//GATEWAY
const GATEWAY_SETTING = setting({
	accid: "default",
	appid: "gateway",
	colid: "documents",
	platform: platform,
	document: {
	  referencePath: "default/gateway/documents/F2JEy0TFZjhd63XOCosX",
	},
	mapItems: {
		referencePath: "default/gateway/grids/HeUqlOFKGrkrSscdAQyw",
	},
});

//ORDER EXPRESS
const ORDER_EXPRESS_SETTING = setting({
	accid: "default",
	appid: "orderExpress",
	colid: "documentsOrderExpress",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	form: {
        referencePath: "default/orderExpress/forms/spApsaSWj2V3W6oYB2NK",
	},
	mapItems: {
		referencePath: "default/orderExpress/grids/Vv06lZeXpZ2aIbpn2yky",
	},
	stockName: {
		referencePath: "default/stockName/documents/sdb5U190VpYe4qoWv9lk",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

const REORDER_INFO : any = {
	name 			: 'Solicitar Troca/Crédito',
	labelNoteClient : 'Motivo da troca',
	disabledReason  : true,
};

//COMMET
const COMMET_SETTING = setting({
	accid: "default",
	appid: "comment",
	colid: "documents",
	platform: platform,
	orderBy: "postdate",
	asc:false,
	map: true,
	mapItems: {
		referencePath: "default/comment/grids/bqBYblXypKsSCWYnKFwf",
	},
	form: {
		referencePath: "default/comment/forms/RUHBFkTuTsKGOiVBbbVr",
	},
});


// PAGE PIX
const PAGE_PIX_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/12NsXNNyBgyRLFNOSA6X",
	},
	document: {
		referencePath: "default/page/documents/iPxPv1RDdTSg9ifqTiUA",
	},
});

// PAGE PIX
const TOP_CART_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/bcOIYBQhPXNpGZ80RiyJ",
	},
	document: {
		referencePath: "default/page/documents/iqvIq3oEkcF5fBFOG2RJ",
	},
});

//FEATURES PRODUCTS
const FEATRES_PRODUCTS_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/TejRU142K4kGBLILI2W3",
	},
	document: {
		referencePath: "default/page/documents/zkD6at38P3CWMMC5wazN",
	}
});

export { 
	THEME_SETTING,
	MENU_FOOTER_STATIC, 
	BANNER_SETTING, 
	ACCOUNT_SETTING, 
	PAGE_SETTING, 
	TRACK_SETTING, 
	MENU_HEADER_STATIC,
	ANALYTICS_SETTING,
	CATEGORY_SETTING, 
	COLOR_VARIANT_SETTING, 
	FAVORITE_SETTING, 
	AUTH_SETTING, 
	SIZE_VARIANT_SETTING, 
	PAYMENT_METHODS_OPTION_SETTING,
	CART_SETTING, 
	FULL_PRODUCT_SETTING, 
	ORDER_SETTING, 
	OPTION_SETTING, 
	HOME_PAGE_SETTING,
	PARTIAL_PRODUCT_SETTING, 
	SLUG_PRODUCT_SETTING,
	SLUG_CATEGORY_SETTING,
	MENU_SETTING,
	COLLECTION_SETTING,
	SLUG_COLLECTION_SETTING,
	COLOR_TABLE_SETTING,
	REORDER_TYPE_OPTION,
	REORDER_SETTING,
	PARTIAL_PRODUCT_VARIANT_SETTING,
	TOP_HEADER_PAGE_SETTING,
	GATEWAY_SETTING,
	ERROS_SETTING,
	ORDER_EXPRESS_SETTING,
	REORDER_INFO,
	COMMET_SETTING,
	TROCA_PAGE_SETTING,
	TRACK_BACK_PAGE_SETTING,
	PAGE_PIX_SETTING,
	TOP_CART_PAGE_SETTING,
	parseSizeVariant,
	FEATRES_PRODUCTS_SETTING,
	BANNERS_TOP_PAGE_SETTING,
	platform
}