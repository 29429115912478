//import 'core-js';
import 'scroll-behavior-polyfill'
import '@splidejs/react-splide/css'
import '@djthoms/pretty-checkbox'
import 'react-aspect-ratio/aspect-ratio.css'
import 'react-modern-drawer/dist/index.css'

// BUG BROWSER ANTIGOS
if (typeof window !== 'undefined') {
	Object.defineProperty(Array.prototype, 'at', {
		enumerable: false,
		writable: true,
	})
}

import NextNProgress from 'nextjs-progressbar'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Toaster } from 'react-hot-toast'
import CookieConsent from 'react-cookie-consent'
import { WhatsAppIcon } from '../../component-nextv3/WhatsAppIcon'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import {
	pageViewsAnalytics,
	useDeviceAnalytics,
	usePeriodsDaysAnalytics,
	useReferrerAnalytics,
	useUniqueUserAnalytics,
	useWeatherAnalytics,
} from '../../core-nextv3/analytics/analytics.api'
import ErrorBoundary from '../../component-nextv3/ErrorBoundary'
import { useScrollRestoration } from '../../core-nextv3/util/useScrollRestoration'
import { useCore } from '../../core-nextv3/core/core'
import { ProductModalHome } from '../ProductModallHome'
import { THEME_SETTING } from '../../setting/setting'
// import { useResponsive } from '@/core-nextv3/util/useResponsive'
import { tagManager4 } from '@/core-nextv3/util/TagManager4'
import { useEffect } from 'react'
import { disableReactDevTools } from '@/core-nextv3/util/disableReactDevTools'
//import { useDocumentSocket } from '@/core-nextv3/core/socket/socket.util'

const AppPage = ({ Component, pageProps }: any) => {
	// const { isDesktop, isClient } = useResponsive()
	const router = useRouter()
	const { user } = useCore()

	useEffect(() => {
		if (router.isReady) {

			if (pageProps.account && pageProps.account.gtm) {
				tagManager4.initialize(pageProps.account.gtm);
				tagManager4.pageView(router.asPath, pageProps.seo?.title, 'b2b', 'ecommerce', window.orientation > -1 ? 'mobile' : 'desktop', 'Pippa', user);
			}

			pageViewsAnalytics(pageProps.seo);
		}

	}, [router.isReady, router.asPath]);

	useEffect(() => {
		disableReactDevTools();

	}, []);

	useScrollRestoration()
	useUniqueUserAnalytics()
	usePeriodsDaysAnalytics()
	useWeatherAnalytics()
	useDeviceAnalytics()
	useReferrerAnalytics()
	// useDocumentSocket(
	//   'default/admin/documents/uPSh5s2Ub0U1EQAKXQ0i',
	//   (data: any) => {
	//     console.error('xxx', data)
	//   }
	// )

		useEffect(() => {
				const script = document.createElement('script');
				script.src = 'https://www.google.com/recaptcha/enterprise.js?render=' + process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
				script.async = true;
				document.head.appendChild(script);
		}, []);
		
	return (
		<>
			<DefaultSeo {...pageProps.seo} />
				<NextNProgress
					color="var(--theme-color);"
					startPosition={0.3}
					stopDelayMs={200}
					height={4}
					showOnShallow={true}
					options={{ easing: "ease", speed: 500 }}
				/>
				<ErrorBoundary router={router}>
					<Header
						categories={pageProps.categories}
						cart={pageProps.cart}
						account={pageProps.account}
						menu={pageProps.menu}
						topHeader={pageProps.topHeader}
						gateway={pageProps.gateway}
					/>
					{pageProps.popupPageDiscount && pageProps?.popupPageDiscount?.published && <ProductModalHome popupPageDiscount={pageProps.popupPageDiscount} />}
					<Component {...pageProps} />
					<Footer
						account={pageProps.account}
						homePage={pageProps.homePage}
					/>
				</ErrorBoundary>
				<Toaster
					position="top-center"
					reverseOrder={true}
					containerStyle={{
						fontSize: 16,
					}}
					toastOptions={{
						duration: 2500,
					}}
				/>
				{!THEME_SETTING.disabledWhatsapp && <WhatsAppIcon account={pageProps.account} />}
				{/* <ReactTooltip/> */}
				<CookieConsent
					location="bottom"
					buttonText="Aceitar"
					cookieName="aodenim1"
					style={{ background: "#2B373B", fontSize: "14px", textAlign: "center" }}
					buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
					contentClasses="cookieConsent"
				>
					<div dangerouslySetInnerHTML={{ __html: THEME_SETTING.cookieConsent }}></div>
				</CookieConsent>
		</>
	);
}

export { AppPage }