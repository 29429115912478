import { THEME_SETTING } from "../../setting/setting";
import { Matrix } from "../model/matrix"

const hasStockByVariant = (product:any, variant:any) =>
{
	if(product && product.stockTable)
	{		
		const stockTable = new Matrix(product.stockTable);
		const data       = stockTable.getValueByVariant(variant);

		if(data)
		{
			for(const key in data)
			{
				if(data[key].disabled)
				{
					return false;
				}
				if(data[key].quantity > 0)
				{
					return true;
				}								
				else if(data[key].binary)
				{
					return true;
				}
			}
		}		

		if(product.negativeStock)
		{		
			return true;
		}
	} 

	return false;
}

const hasStockByListVariant = (product:any, list:any) =>
{
	if(THEME_SETTING.noStock)
	{
		return true;
	}

	if(product && product.stockTable)
	{
		const stockTable = new Matrix(product.stockTable);
		const data     	 = stockTable.getValueByListVariant(list);
		const isLeaf     = list && stockTable.variant && list.length == stockTable.variant.length; // DEPOIS VERIFICA SE ISLEAF É NECESSARIO		

		if(data) 
		{
			for(const key in data)
			{				 
				if(isLeaf && data[key].disabled)
				{
					return false;
				}
				else if(data[key].quantity > 0) 
				{
					return true;
				}
				else if(data[key].binary)
				{
					return true;
				}
			}	

			if(product.negativeStock)
			{
				return true;
			}	
		}			
	}

	return false;
}

const hasStockProduct = (product:any) =>
{
	if(THEME_SETTING.noStock)
	{
		return true;
	}

	if(product && product.stockTable && product.stockTable.data)
	{
		if(product.negativeStock)
		{
			return true;
		}

		let has = false;

		for(const key in product.stockTable.data)
		{
			const data = product.stockTable.data[key];

			if(data)
			{
				// SE NO MINIMO 1 TIVER VARIANTE
				if(data.quantity > 0)
				{
					has = true;
					break;
				}
				else if(data.binary)
				{
					has = true;
					break;
				}
			}				
		}

		return has;
	}

	return false;
}

const quantityStockByListVariant = (product:any, list:any) =>
{
	if(THEME_SETTING.noStock)
	{
		return 999999;
	}
	
	if(product && product.stockTable)
	{
		if(product.negativeStock)
		{
			return 999999;
		}
		
		const stockTable = new Matrix(product.stockTable);
		const data     	 = stockTable.getValueByListVariant(list);

		for(const key in data)
		{
			if(data)
			{
				if(data[key].quantity > 0)
				{
					return data[key].quantity;
				}	
				else if(data[key].binary)
				{
					return 999999;
				}	
			}
		}
	}

	return 0;
}

const shippingByListVariant = (product:any, price:any, list:any) =>
{
	if(list && product.shippingTable)
	{
		const shippingTable = new Matrix(product.shippingTable);
		const data     	    = shippingTable.getValueByListVariant(list);

		for(const key in data)
		{
			if(data && data[key])
			{
				data[key].totalItems = price || 0;

				return data[key];
			}
		}		
	}
	else if(product.width && product.height && product.length && product.weight)
	{
		return {				
			width  	   : product.width,
			height 	   : product.height,
			length 	   : product.length,
			weight 	   : product.weight,
			totalItems : price
		};
	}

	return;
}

const getTotalQuantityTable = (quantityTable:any) =>
{
	let total = 0;

	if(quantityTable)
	{
		for(const key in quantityTable)
		{
			total += quantityTable[key].quantity;
		}	
	} 

	return total;
}

export { 
	hasStockByVariant, 
	hasStockByListVariant, 
	hasStockProduct,
	quantityStockByListVariant,
	shippingByListVariant,
	getTotalQuantityTable,
}


