import React from "react"
import { addDocument } from "../../core-nextv3/document/document.api"
import { ERROS_SETTING } from "../../setting/setting"
import styles from "./styles.module.scss";
class ErrorBoundary extends React.Component <any, any> 
{
    constructor(props:any) 
    {
        super(props)
    
        // Define a state variable to track whether is an error or not
        this.state = { hasError: false }
    }
    
    static getDerivedStateFromError(error:any) 
    {
        // Update state so the next render will show the fallback UI
        return { hasError: true }
    }

    componentDidCatch(error:any, errorInfo:any) 
    {
        // You can use your own error logging service here
        //console.log({ error, errorInfo });

        addDocument(ERROS_SETTING.merge({
            data : {
              error     : error,
              errorInfo : errorInfo,
              path      : this.props?.router?.asPath || null,
              userAgent : window?.navigator?.userAgent || null,
            }
        }));
    }

    render() 
    {
        // Check if the error is thrown
        if(this.state.hasError) 
        {
            // You can render any custom fallback UI
            return (
                <main className={styles.boundary}>
                    <div className={styles.info}>
                    <p>LAMENTAMOS, MAS ESTA PÁGINA JÁ NÃO ESTÁ DISPONÍVEL NO NOSSO SITE.</p>
                        <button onClick={(e) => this.props?.router?.push(`/`)}>Voltar à página principal</button>
                    </div>
                </main>
            )
        }
    
        // Return children components in case of no error    
        return this.props.children
    }
}
  
export default ErrorBoundary