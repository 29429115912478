const customSelectStyles: any = {
    control: (base: any, state: any) => ({
      ...base,
      borderRadius: "var(--border-radius)",
      borderColor: state.isFocused
        ? "var(--border-color)"
        : "var(--border-color)",
      boxShadow: state.isFocused ? "none" : null,
      "&:hover": {
        borderColor: "var(--border-color)",
      },      
      color: "var(--select-color, #000)",
      backgroundColor : 'var(--select-background-color, #fff)',
      padding: "0",
    }),
    container : (base: any, state: any) => ({
      ...base,  
      width:'100%',
    }),
    singleValue: (styles: any) => ({ 
        ...styles, 
        color: 'var(--select-color, #000)', 
        textAlign: "left",
    }),
    indicatorsContainer: (styles: any, state: any) => ({
      ...styles,
      color: 'var(--select-color, #000)',
      padding:'0',  
    }),
    indicatorSeparator: (styles: any, state: any) => ({
      display:'none'
    }),    
    placeholder: (styles: any, state: any) => ({
      ...styles,
      color: 'var(--select-placeholder, #808080)',
      textAlign: "left",
      //fontSize: "1.6rem",
    }),
    clearIndicator: (styles: any, state: any) => ({
      ...styles,
      padding:'0 5px 0 0',
      height: '100%',
      alignItems: 'center',
      display: 'flex',
    }),
    dropdownIndicator: (styles: any, state: any) => ({
      ...styles,
      padding:'0 5px 0 0',
      height: '100%',
      alignItems: 'center',
      display: 'flex',
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      padding:'0 0 0 5px',
      textAlign:'center'
    }),     
    menu: (styles: any, state: any) => ({
      ...styles,
      margin:'0',
    }),     
};

export {
  customSelectStyles
}