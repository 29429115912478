import styles from "./styles.module.scss";
import { innerHTML } from "../../core-nextv3/util/util";
import { useResponsive } from "../../core-nextv3/util/useResponsive";

export const CartMessage = ({ cartMessage }: any) => {
  // console.log("aqui", cartMessage);
  const { isDesktop } = useResponsive();

  return (
    <div className={styles.cartMessage}>
      <div className={styles.content}>
        {/* {cartMessage?.announcement?.map((message: any, index: any) => (
          <div className={styles.top} key={index}>
            {message?.imageDesktop && (
              <img src={message?.imageDesktop?._url} alt="" />
            )}
            <div className={styles.text}>
              <h1>{message?.name}</h1>
              <div
                dangerouslySetInnerHTML={innerHTML(message?.content)}
                className={styles.text}
              ></div>
            </div>
          </div>
        ))} */}
        <div className={styles.description}>
          {/* <h1>{cartMessage?.name}</h1> */}

          {isDesktop && cartMessage?.desktop &&
            <img src={cartMessage?.desktop?._url} alt="" />
          }
          
          {!isDesktop && cartMessage?.mobile &&
            <img src={cartMessage?.mobile?._url} alt="" />
          }
          <div
            dangerouslySetInnerHTML={innerHTML(cartMessage?.description)}
            className={styles.text}
          ></div>
        </div>
      </div>
    </div>
  );
};
