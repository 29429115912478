import styles from "./styles.module.scss";
import { useState } from "react";
import { useRouter } from "next/router";
import Drawer from "react-modern-drawer";
import { FaRegUserCircle } from "react-icons/fa";
import { MenuTypeMobile } from "../MenuTypeMobile";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useCore } from "../../core-nextv3/core/core";
import IUser from "../../core-nextv3/interface/i.user";
import { MENU_FOOTER_STATIC } from "../../setting/setting";
import { randomString } from "../../core-nextv3/util/util";
import { AccordionSubMenuItem } from "../AccordionSubMenuItem";

export const MobileMenu = ({ handleModal, categories, menu, isOpen, setIsOpen }: any) => 
{
    //const router = useRouter();
    const { user } = useCore();
    // const [selected, setSelected] = useState();
    // const [clothesSubMenu, setClothesSubMenu] = useState(false);

    // const pushMenu = (path:string) =>
    // {
    //     window.scrollTo(0, 0);
    //     router.push(path);        
    // }

    // const getIcon = (i: number) => {
    //   if (i == 0) {
    //     return <BsQuestionLg />
    //   }
    //   else {
    //     return <IoIosInformationCircleOutline />
    //   }
    // }

    return (
      <Drawer
        open={isOpen}
        onClose={setIsOpen}
        size="100%"
        direction="left"
        duration={300}
        className={styles.container}
      >
        <div className={styles.content} onClick={(e) => e.stopPropagation()}>
          <AiOutlineCloseCircle
            onClick={setIsOpen}
            className={styles.closeMobileMenuButton}
          />
          <div
            onClick={() => {
              handleModal(false);
            }} className={styles.user}>
              <a href="/perfil">
                <FaRegUserCircle className={styles.Iconuser} />
                {user ? (
                  <p>{user.name}</p>
                )
                  :
                  (
                    <p>Acessar</p>
                  )}
              </a>            
          </div>
          <div className={styles.mobileMenu}>
            <div
              className={styles.mobileItem}
              onClick={() => {
                handleModal(false);
              }}
            >
              <p>
                <a href="/">
                  {/* <AiOutlineHome className={styles.Icon} /> */}
                  Home
                </a>
              </p>
            </div>
            <div className={styles.mobileItem}>
              <div className={styles.menuTypeMobile}>
                {menu?.map((item: any) => (
                  item.status && <MenuTypeMobile
                    key={randomString(8)}
                    className={styles.menuTypeItemMobile}
                    handleModal={handleModal}
                    item={item}
                  />
                ))}
              </div>
            </div>

            {/* <div className={styles.divisor}></div> */}

            {/* <p
                    onClick={() => {
                      pushMenu(`/perfil`);
                      handleModal(false);
                    }}
                  >
                    Conta
                  </p>
                  <p
                    onClick={() => {
                      pushMenu("/lista-de-desejos");
                      handleModal(false);
                    }}
                  >
                    Lista de desejos
                  </p> */}

            <div className={styles.otherPages + ' otherPages'}>
              {MENU_FOOTER_STATIC && (MENU_FOOTER_STATIC.map((item: any) =>
              (
                <AccordionSubMenuItem
                  key={item?.id}
                  title={item?.label}
                >
                  {item.children.map((item2: any) => (
                    <p
                      key={item2?.id}
                      className={styles.answer}
                    >
                      <p
                        onClick={() => { handleModal(false) }}
                        className={styles.answerText}
                      >
                        <a href={item2.url}>
                          {item2.label}
                        </a>                        
                      </p>
                    </p>
                  ))}
                </AccordionSubMenuItem>
              )))}
            </div>

            {/* <div className={styles.mobileItem}>
                    <p
                      onClick={() => {
                        pushMenu("/loja/sobre-nos");
                        handleModal(false);
                      }}
                    >
                      <IoIosInformationCircleOutline />
                      Sobre Nós
                    </p>
                  </div>

                  <div className={styles.divisor}></div>

                  <div className={styles.mobileItem}>
                    <p
                      onClick={() => {
                        pushMenu("/loja/sustentabilidade");
                        handleModal(false);
                      }}
                    >
                      <GiWorld />
                      Sustentabilidade
                    </p>
                  </div>
                  <div className={styles.divisor}></div>
                  */}
          </div>
        </div>
      </Drawer>
    );
};
