import styles from "./styles.module.scss";
import { useState } from "react";

export const CarouselTopHeader = ({ topHeader }: any) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  return (
    <section className={styles.fadeInContent}>
      {/* {isPlaying ? (
        <BsPause onClick={() => setIsPlaying(!isPlaying)} />
      ) : (
        <BsPlay onClick={() => setIsPlaying(!isPlaying)} />
      )} */}

      <div className={styles.features}>
        <div className={`${styles.marquee} ${!isPlaying && styles.pause}`}>
          {topHeader?.list?.map((item: any, index: any) => (
            <div className={styles.item} key={index}>
              <p>
                {item?.name}
              </p>
              <span>|</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};