import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { randomString } from "../../core-nextv3/util/util";

export const MenuType = ({ menu }: any) => 
{
    // console.log("menu", menu)
    //const { push: router } = useRouter();

    const getMenu = (item: any) => 
    {
        if(item.status)
        {
            switch (item?.type?.value) 
            {
              case "path":
                return (
                  <>
                  {
                    item?._children && item?._children?.length > 0 ?
                      <li className={styles.dropdown} key={randomString(8)}>
                        <a href={item?.link} className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '')}>
                            {item?.name}
                        </a>
                        <ul className={styles.dropdownMenu}>
                          {item?.link && <li
                            key={randomString(8)}
                          >
                            {item?.addSeeAll &&
                              <a href={item?.link}>
                                Ver Todos
                              </a>
                            }
                          </li>}
                          {
                            item?._children?.map((child:any, key:any) => (
                                getMenu(child)
                            ))
                          }
                        </ul>
                      </li> 
                      :
                      <li key={randomString(8)}>
                          <a href={item?.link} className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '')}>
                            {item?.name}
                          </a>
                      </li> 
                  }
                  </>
                );
              case "category":
                return (
                  <>
                  {
                    item?.category?._children && item?.category?._children?.length > 0 ?
                      <li className={styles.dropdown} key={randomString(8)}>
                        <a href={`/categoria/${item?.category?.slug}/`} className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '')}>
                            {item?.category?.name}
                        </a>
                        <ul className={styles.dropdownMenu}>
                          {item?.addSeeAll && item?.category?.slug && <li
                            key={randomString(8)}
                          >
                              <a href={`/categoria/${item?.category?.slug}/`}>
                                Ver Todos
                              </a>
                          </li>}
                          {
                            item?.category?._children?.map((child:any, key:any) => (
                                getMenu({ type : { value : 'category' }, category : child })   
                            ))
                          }
                        </ul>
                      </li> 
                      :
                      <li key={randomString(8)}>
                          <a href={`/categoria/${item?.category?.slug}/`} className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '')}>
                              {item?.category?.name}
                          </a>
                      </li> 
                  }                
                  </>
                );
              case "collection":
                return (
                  <>
                    {
                        item?._children && item?._children?.length > 0 ?
                        <li className={styles.dropdown} key={randomString(8)}>
                            <a href={`/colecao/${item?.collection?.slug}/`} className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '')}>
                              {item?.name}
                            </a>
                            <ul className={styles.dropdownMenu}>
                              {item?.addSeeAll && item?.collection?.slug && <li
                                key={randomString(8)}
                              >
                                <a href={`/colecao/${item?.collection?.slug}/`}>
                                  Ver Todos
                                </a>
                              </li>}
                              {
                                item?._children?.map((child:any, key:any) => (
                                    getMenu(child)
                                ))
                              }
                            </ul>
                          </li> 
                          :
                          <li key={randomString(8)}>
                              <a href={`/colecao/${item?.collection?.slug}/`} className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '')}>
                                {item?.name}
                              </a>
                          </li> 
                      }
                  </>
                );
              default:
                return null;
            }
        }        
    };

    return (
      <div className={styles.menuType}>
        {menu?.map(
          (item: any) =>
            item.status && (
              <ul className={styles.menu} key={randomString(8)}>
                {getMenu(item)}
              </ul>                           
            )
        )}
      </div>
    );
};
