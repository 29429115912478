import styles from "./styles.module.scss"
import { toast } from "react-hot-toast";
import Drawer from "react-modern-drawer";
import { GrClose } from "react-icons/gr";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useCore } from "../../core-nextv3/core/core";
import { getRecaptcha, innerHTML } from "../../core-nextv3/util/util";
import ErrorMessage from "../../component-nextv3/ErrorMessage";
import { mergeCart, setCouponCart } from "../../core-nextv3/cart/cart.api";
import { validateEmail } from "../../core-nextv3/util/validate";
import { AUTH_SETTING, CART_SETTING, THEME_SETTING } from "../../setting/setting";
import { AnimatedLoading } from "../../component-nextv3/AnimatedLoading";
import { ImageSet } from "../../component-nextv3/ImageSet";
import { addUserAuth, loginAuth } from "../../core-nextv3/auth/auth.api";
interface ProductModalHomeProps {
    popupPageDiscount: any
}

export const ProductModalHome: React.FC<ProductModalHomeProps> = ({ popupPageDiscount }) => 
{
    const [isOpen, setIsOpen]                                = useState<boolean>(false);
    const [isSend, setIsSend]                                = useState<boolean>(false);
    const [cookies, setCookie]                               = useCookies(['homePopup']);
    const { awaitLoadingHTTP, setAwaitLoadingHTTP, setCart, cart, setUser } = useCore();

    const {
      register,
      handleSubmit,
      setValue,
      watch: watch,
      formState: { errors, isSubmitted },
    } = useForm();

    useEffect(() => 
    {
        if(isOpen) 
        {
            document.body.style.overflow = "hidden";
        } 
        else 
        {
            document.body.style.overflow = "auto";
        }
        
    }, [isOpen]);

    useEffect(() => {
    //   setTimeout(() => {
    //     if (document) {
    //       const element = document.getElementById("whatsPopover");
    //       if (element) {
    //         element!.style!.display = "none";
    //       }
    //       return;
    //     }
    //   }, 5000);
  
      if(!cookies.homePopup) 
      {
          setTimeout(() => {
              setIsOpen(true);
        }, 3000);   
      }

    }, []);

    const onClose = () => 
    {        
        const expires = new Date()
    	expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000));

        setCookie('homePopup', 'close', { path: '/', expires });

        setIsOpen(false);
    };

    const onSubmit = async (data: any) => 
    {
        const token = await getRecaptcha("addUser");

        if(token) 
        {
            setAwaitLoadingHTTP(true);

            // CADASTRO
            const newData = {
                token : token,
                data: {
                    email	 : data.email.toLowerCase(),
                    password : data.email.toLowerCase()
                },
            };

            const result = await addUserAuth(AUTH_SETTING.merge(newData));
            
            // CADASTRO NOVO FAZ LOGIN
            if(result.status) 
            {
                const token = await getRecaptcha("login");

                // LOGIN
                const result2 = await loginAuth(AUTH_SETTING.merge({
                    login	 : data.email.toLowerCase(),
                    password : data.email.toLowerCase(),
                    token    : token
                }));

                // MERGE CART SESSION
                await mergeCart(CART_SETTING.merge({
                    document : {
                        referencePath : cart?.referencePath
                    }
                }));

                setUser(result2.data);
            }

            const couponData = {
                _code : popupPageDiscount.coupon.toUpperCase().replace(/\s/g,''),
            };

            const result2 = await setCouponCart(CART_SETTING.merge(couponData));

            setCart(result2.data);         

            toast("Seu Cupom foi adicionado ao carrinho!", { icon : '👏'});

            const expires = new Date()
            expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));

            setCookie('homePopup', 'close', { path: '/', expires });

            setIsSend(true);
            
            setAwaitLoadingHTTP(false);

            onClose();
        }        
    };

    return(
        <>
            {isOpen && <Drawer
                className={styles.productModalHome}
                open={isOpen}
                onClose={onClose}
                direction="top"
                duration={0}
            >
                <section>
                    <GrClose className={styles.close} onClick={onClose} />
                    <div className={styles.image}>
                        <ImageSet
                            aspectRatio={THEME_SETTING.imagePopupModalHome || 1024/1024} 
                            image={popupPageDiscount?.image}
                            domainFrom={THEME_SETTING.domainFrom} 
                            domainTo={THEME_SETTING.domainTo}
                        />
                    </div>                    
                    <div className={styles.content}>
                        <h1>{popupPageDiscount?.title}</h1>
                        <h2>{popupPageDiscount?.subTitle}</h2>
                        <p>{popupPageDiscount?.discount}</p>
                        <h2>{popupPageDiscount?.information}</h2>

                        {!isSend ? <div className={styles.emailInput}>
                            <input
                            type="email"
                            placeholder="Digite seu email..." 
                            {...register("email", {
                                validate: (value) => validateEmail(value) || "E-mail inválido",
                            })}
                            />
                            {errors.email && <ErrorMessage message={errors.email?.message}/>}

                            <button onClick={handleSubmit(onSubmit)}>                  
                                {awaitLoadingHTTP ? (
                                    <AnimatedLoading />
                                ) : (
                                    "Cadastre-se"
                                )}
                            </button>
                        </div> : <div className={styles.coupon}>Cupom: {popupPageDiscount.coupon}</div>}

                        {/* <small>
                            *Cupom não válido para outras promoções, descontos e seção OFF
                        </small> */}
                        <div
                            dangerouslySetInnerHTML={innerHTML(popupPageDiscount?.description)}
                            className={styles.description}
                        ></div>
                    </div>
                    {/* <button className={styles.closeButton} onClick={onClose}>Fechar</button> */}
                </section>
            </Drawer>}
        </>        
    );
}