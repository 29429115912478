import styles from "./styles.module.scss";
import Select from 'react-select';
import { ImageSet } from "../ImageSet";
import { useRouter } from "next/router";
import { BsCheck } from "react-icons/bs";
import { useEffect, useState } from "react";
import { FiCheck, FiShoppingBag } from "react-icons/fi";
import { customSelectStyles } from "../../core-nextv3/util/util.style";
import { hasStockByListVariant, hasStockProduct } from "../../core-nextv3/stock/stock.util";
import { firstProductImage, getImagesListVariantByProduct, getVideosListVariantByProduct, imageColorTableByVariant, parserQueryToImagesListVariant, productImages, setTypeinItemsVariant, validateListVariant, validateQuantityTable } from "../../core-nextv3/product/product.util";
import { productVideos } from "../../core-nextv3/video/product.util";
import { combineItemsVariant, getPathListVariant } from "../../core-nextv3/model/matrix";
import { ItemQuantity } from "./item.quantity";
import { generateKey, objectToArray } from "../../core-nextv3/util/util";
import toast from "react-hot-toast";
import { useCore } from "../../core-nextv3/core/core";
import { THEME_SETTING } from "../../setting/setting";
import { useResponsive } from "@/core-nextv3/util/useResponsive"; 
import { ItemQuantityTwo } from "./item.quantityTwo";

export const VariantSelector = ({ 
    product, 
    colorTable, 
    changeVariant, 
    changeQuantity,
    changeQuantityTable,
    changeImagesVariants, 
    changeVideosVariants, 
    changeImage,
    changeVideo, 
    loadingAddToCart, 
    handleBuyQuantity,
    handleBuyQuantityTable,
    disabledAddCart=false,
    disabledQuantity=false,
    disabledVariantTypeLabel=false,
    showIconCheck=false,
    layout='button',
    title='',
    noStock= false,
    maxLevel=100,
}:any) => 
{
    const { query, asPath, isReady, push }      = useRouter();
    const { user }                              = useCore();
    const [listVariants, setListVariants]       = useState<any>([]);
    const [mapListVariants, setMapListVariants] = useState<any>({});
    const [variant, setVariant]                 = useState<any>([]);
    const [matrix, setMatrix]                   = useState<any>([]);
    const [quantity, setQuantity]               = useState<any>(1);
    const [quantityTable, setQuantityTable]     = useState<any>({});
    const [isLoading, setIsLoading]             = useState<any>(false);     
    const { isDesktop }                         = useResponsive();

    async function handleChangeVariant(index:number, item:any)
    {
        setIsLoading(true);

        listVariants[index] = item;
        setListVariants([...listVariants]);

        if(changeVariant)
        {
            changeVariant(listVariants);
        }        

        dispatchImagesVariant(listVariants);

        setIsLoading(false);
    }

    async function handleChangeListVariant(list:any)
    {
        setIsLoading(true);

        setListVariants(list);

        if(changeVariant)
        {
            changeVariant(list);
        }        

        dispatchImagesVariant(list);

        setIsLoading(false);
    }

    async function dispatchImagesVariant(list:any)
    {
        const imageType : any   = query.imageType || 'images';
        const imagesListVariant = getImagesListVariantByProduct(product, list);

        if(imagesListVariant && imagesListVariant.length > 0)
        {   
            if(changeImage)
            {
                const images = productImages(product, imagesListVariant, imageType);

                if(images)
                {
                    changeImage(images);
                }                
            }
        }
        else
        {
            if(changeImage)
            {
                const images = productImages(product, null, imageType);

                if(images)
                {
                    changeImage(images);   
                }                
            }
        }      
        
        if(changeImagesVariants)
        {
            changeImagesVariants(imagesListVariant);
        }

        const videoType : any   = query.videoType || 'videos';
        const videosListVariant = getVideosListVariantByProduct(product, list);

        if(videosListVariant && videosListVariant.length > 0)
        {   
            if(changeVideo)
            {
                const videos = productVideos(product, videosListVariant, videoType);                                
                changeVideo(videos);
            }
        }
        else
        {
            if(changeVideo)
            {
                const videos = productVideos(product, null, videoType);
                changeVideo(videos);
            }
        }

        if(changeVideosVariants)
        {
            changeVideosVariants(videosListVariant);
        }
    }

    function handleChangeQuantity(variants:any, value:number) 
    {
        setQuantity(value);
        
        if(changeQuantity)
        {
            changeQuantity(value);
        }
    }

    function handleChangeQuantityTable(listVariant:any, value:number) 
    {
        const path            = getPathListVariant(listVariant);
        quantityTable[path]   = { quantity : value };
        mapListVariants[path] = listVariant;

        if(value === 0)
        {
            delete quantityTable[path];
        }

        setQuantityTable(quantityTable);
        setMapListVariants(mapListVariants);

        if(changeQuantityTable)
        {
            changeQuantityTable(objectToArray(mapListVariants), quantityTable);
        }
    }

    function getQuantityTable(listVariant:any) 
    {
        const path = getPathListVariant(listVariant);
        
        if(quantityTable[path])
        {
            return quantityTable[path].quantity;
        }

        return 0;
    }

    function mergeIndexListVariant(item:any, index:any) 
    {
		const listVariant  = [...listVariants];

        if(listVariant[index])
        {
            listVariant[index] = item;
        }

        return listVariant;
	}

    const getOptions = (items:any, index:number) =>
    {
        const items2 = [];
    
        for(const item of items)
        {
            const item2   = Object.assign({}, item);
            item2.disabled = !hasStockByListVariant(product, mergeIndexListVariant(item2, index));
            items2.push(item2);
        }

        return items2;
    }

    const getStockStyle = (product:any, variant:any, totalVariant:number, indexVariant:number) =>
    {
        if(noStock) 
        {
            return 'inStock'
        }

        if(totalVariant - 1 == indexVariant)
        {
            return hasStockByListVariant(product, mergeIndexListVariant(variant, indexVariant)) ? 'inStock' : 'outStock'
        }        

        return 'inStock';
    }   

    const getStockStyleGrid = (product:any, list:any) =>
    {
        return hasStockByListVariant(product, list) ? 'inStock' : 'outStock';
    }   
    
    const getSelectedStyleGrid = (list:any) =>
    {
        if(listVariants && list && listVariants.length == list.length)
        {
            let all = false;

            for(let i = 0; i < listVariants.length; i++)
            {
                if(listVariants[i].id == list[i].id)
                {
                    all = true;
                }
                else
                {
                    all = false;
                    break;
                }
            }

            if(all)
            {
                return 'selected';
            }
        }

        return 'no-selected';        
    }

    const buy = () =>
    {
        if(THEME_SETTING?.showPricesUnlogged === false && !user)
        {
            return push("/login");
        }

        if(layout == 'button' || layout == 'select')
        {
            if(!validateListVariant(product, listVariants))
            {
                return toast.error("Selecione o produto desejado!", {
                    duration: 2000,
                });
            }
            else
            {
                handleBuyQuantity(listVariants, quantity);
            }            
        }
        else if(layout == 'gridHorizontal' || layout == 'gridVertical' || layout == 'matrix' || layout == 'table')
        {
            if(!validateQuantityTable(quantityTable))
            {
                return toast.error("Adicione a quantidate ao produto!", {
                    duration: 2000,
                });
            } 
            else
            {
                handleBuyQuantityTable(objectToArray(mapListVariants), quantityTable);
            }
        }
    }

    const firstProductImageByButton = (item:any, index:number) => 
    {
        if(index == 0)
        {
            return firstProductImage(product, [item]);
        }

        return true;
    }

    useEffect(() =>  
    {
        if(isReady) 
        {
            // DEFINE AS VARIANTES QUE SERAO EXIBIDAS, POIS TEM CASOS QUE NÃO PRECISA EXIBIR TODAS
            let _variants;
            let _listVariants = [];

            if(product.skuVariant) // GERALMENTE ITENS DO PRODUTO
            {
                let skuVariant = product.skuVariant || [];

                //COMPLETA AS VARIANTES DA QUERY OU SKUVARIANT
                for(let i = skuVariant.length; i < product.variant.length; i++)
                {
                    skuVariant.push(product.variant[i]);
                }
                
                _variants = skuVariant;

                for(const item of _variants)
                {                    
                    _listVariants.push(item.items[0]);
                }
            }
            else if(product.imagesListVariant) // LISTA DE PRODUTO
            {
                _variants = product.variant;
                _listVariants = [...product.imagesListVariant];
            }
            else // VIEWER DO PRODUTO
            {
                _variants = product.variant;

                // SELECIONA AS VARIANTES DEFAULT
                const listVariant = parserQueryToImagesListVariant(product, query, noStock);                        
                _listVariants = [...listVariant];
            }

            //ADICIONA O TYPES DENTROS DAS VARIANTES
            _variants = setTypeinItemsVariant(_variants);   
            
            setVariant(_variants);
            setMatrix(combineItemsVariant(_variants))
            setListVariants(_listVariants);
            
            if(changeVariant)
            {
                changeVariant(_listVariants);
            }            

            dispatchImagesVariant(_listVariants);            
        }

    }, [isReady, asPath]);
    
    const displayLayout = () =>
    {
        switch (layout) 
        {
            case 'button' :

                return variant && variant?.map((itemVariant: any, index: any) => (
                    <>
                        {maxLevel >= (index + 1) && <div className={styles.productVariant} key={index}>
                            {!disabledVariantTypeLabel && <span className={styles.nameVariant}>{itemVariant?.type?.name}</span>}
                            <div className={styles.variantItem}>
                                {itemVariant?.items.map((item: any, index2: any) => (
                                    firstProductImageByButton(item, index) && 
                                    <div key={index2} className={styles[getStockStyle(product, item, variant.length, index)] + ' ' + (listVariants[index] && listVariants[index].value == item.value ? styles['selected'] : '')}>
                                        {colorTable && colorTable.status && imageColorTableByVariant(colorTable, item) ? (
                                            <p
                                            className={styles.colorVariantImage}
                                            data-tip={item.label}
                                            onClick={(e) => handleChangeVariant(index, item)}
                                            >
                                                <ImageSet width={480} image={imageColorTableByVariant(colorTable, item)} aspectRatio={1} />
                                                {showIconCheck && listVariants[index] && listVariants[index].value == item.value && <BsCheck />}
                                            </p>
                                        ) : (
                                            //(!itemVariant?.type?.skuImage || firstProductImage(product, [item.value])) && 
                                            <p
                                            className={styles.colorVariantText}
                                            // data-tip={item.label}
                                            onClick={(e) => handleChangeVariant(index, item)}
                                            >
                                            {item && item.value &&
                                                item?.label 
                                            }
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>               
                        </div>} 
                    </>                    
                ))

            case 'select':
                
                return (
                    <>
                        {title && <><p className={styles.title}>{title}</p><hr/></>}              
                        {variant && variant?.map((variant: any, index: any) => (
                            <>
                                <div className={styles.productVariant + ' ' + styles[layout]} key={index}>
                                    <span className={styles.nameVariant}>{variant?.type?.name}</span>
                                    <div className={styles.variantItem}>                                    
                                        <Select
                                            placeholder="Selecionar"
                                            options={isLoading ? [] : getOptions(variant.items, index)}
                                            styles={customSelectStyles}
                                            value={listVariants[index]}
                                            isOptionDisabled={(option) => option.disabled}
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(e:any) => handleChangeVariant(index, e)}
                                        />
                                    </div>
                                </div> 
                                <hr/>
                            </>                     
                        ))}
                    </>)            

            default:
                return null;
        }
    }

    const quantityLayout = () =>
    {
        switch (layout) 
        {
            case 'button' :
            case 'select' :

                return !disabledQuantity && <ItemQuantity product={product} 
                                                          variants={listVariants}
                                                          changeQuantity={handleChangeQuantity}/>

            case 'matrix' :

                return (
                    <>
                        {title && <><p className={styles.title}>{title}</p><hr/></>}                                  
                        <>
                            <div className={styles.productVariant + ' ' + styles[layout]}>
                                <div key={generateKey()} className={' ' + styles.row + ' '}>
                                    {colorTable && colorTable.status ? (
                                        <div className={styles.variantItem}>                                            
                                            <p className={styles.colorVariantImage + ' ' + styles.colorVariantImageHeader}>
                                            </p>
                                        </div>
                                    ) : (
                                        <div className={styles.col} key={generateKey()}>
                                            <p className={styles.colorVariantText}>
                                            </p>
                                        </div>
                                    )}
                                    {variant[1]?.items?.map((item:any, index:any) => (
                                        <div className={styles.col + ' ' + styles.head + ' ' + styles[getStockStyle(product, item, variant.length, 1)]} key={generateKey()}>
                                            <p className={styles.colorVariantText}>{item.label}</p>
                                        </div>
                                    ))}
                                </div>
                                {variant && variant.length >= 2 && variant[0]?.items?.map((item:any) => (
                                    <div key={generateKey()} className={' ' + styles.row + ' '}>                                        
                                        {colorTable && colorTable.status && imageColorTableByVariant(colorTable, item) ? (
                                            <div className={styles.col + ' ' + styles.variantItem}>
                                                <div className={(listVariants[0] && listVariants[0].value == item.value ? styles['selected'] : '')}>
                                                    <p
                                                    className={styles.colorVariantImage}
                                                    data-tip={item.label}
                                                    onClick={(e) => handleChangeVariant(0, item)}
                                                    >
                                                        <ImageSet width={480} image={imageColorTableByVariant(colorTable, item)} aspectRatio={1} />
                                                        {showIconCheck && listVariants[0] && listVariants[0].value == item.value && <BsCheck />}
                                                    </p>
                                                </div>                                    
                                            </div>
                                        ) : (
                                            <div className={styles.col + ' ' + styles.variantItem}>
                                                <div className={(listVariants[0] && listVariants[0].value == item.value ? styles['selected'] : '')}>
                                                    <p
                                                    className={styles.colorVariantText}
                                                    onClick={(e) => handleChangeVariant(0, item)}
                                                    >
                                                    {item && item.value &&
                                                        item?.label 
                                                    }
                                                    </p>
                                                </div>
                                            </div>
                                        )}                                
                                        {variant[1]?.items?.map((item2:any, index:any) => (
                                            <div className={styles.col + ' ' + styles[getStockStyleGrid(product, [item, item2])]} key={generateKey()}>
                                                <div className={styles.itemQuantity}>
                                                    <ItemQuantity product={product} 
                                                                    variants={[item, item2]}
                                                                    disabledLabel={true}
                                                                    value={getQuantityTable([item, item2])}
                                                                    changeQuantity={handleChangeQuantityTable}/>
                                                </div>
                                            </div>
                                        ))}                                            
                                    </div>
                                ))}                                    
                            </div> 
                        </>                     
                    </>) 
                    
            case 'table' :
                
                return(
                    <div className={styles.productTable}>
                        <table>
                            <thead>
                                <tr className={styles.variantItem}>
                                    {!isDesktop &&
                                        <td className={styles.variantItemImage}>
                                            <img src="/assets/logo.png" alt="" />
                                        </td>
                                    }
                                </tr>

                                {variant[1]?.items?.map((item:any, index:any) => (
                                    <tr key={generateKey()}>
                                        <td>{item?.label}</td>
                                    </tr>
                                ))}
                            </thead>

                            {variant && variant.length >= 2 && variant[0]?.items?.map((item:any, index: any) => (
                                firstProductImage(product, [item]) && <tbody key={generateKey()} className={' ' + styles.row + ' '}>                                        
                                    {colorTable && colorTable.status && imageColorTableByVariant(colorTable, item) ? (
                                        <tr className={styles.col + ' ' + styles.variantItem}>
                                            <td className={(listVariants[0] && listVariants[0].value == item.value ? styles['selected'] : '')}>
                                                <p
                                                    className={styles.colorVariantImage}
                                                    data-tip={item.label}
                                                    onClick={(e) => handleChangeVariant(0, item)}
                                                >
                                                    <ImageSet width={480} image={imageColorTableByVariant(colorTable, item)} aspectRatio={1} />
                                                    {showIconCheck && listVariants[0] && listVariants[0].value == item.value && <BsCheck />}
                                                </p>
                                                <p className={styles.nameColor}>{item?.label}</p>                               
                                            </td> 
                                        </tr>
                                    ) : (
                                        <tr className={styles.col + ' ' + styles.variantItem}>
                                            <td className={(listVariants[0] && listVariants[0].value == item.value ? styles['selected'] : '')}>
                                                <p
                                                className={styles.colorVariantText}
                                                onClick={(e) => handleChangeVariant(0, item)}
                                                >
                                                {item && item.value &&
                                                    item?.label 
                                                }
                                                </p>
                                            </td>
                                        </tr>
                                    )}                                
                                    {variant[1]?.items?.map((item2:any) => (
                                        <tr className={styles.col + ' ' + styles[getStockStyleGrid(product, [item, item2])]} key={generateKey()}>
                                            <td className={styles.itemQuantity + ' ' + styles.head + ' ' + styles[getStockStyle(product, item, variant.length, index)]}>
                                                <ItemQuantityTwo
                                                    item={item}
                                                    product={product} 
                                                    disabledLabel={true}
                                                    variants={[item, item2]}
                                                    getStockStyle={getStockStyle}
                                                    value={getQuantityTable([item, item2])}
                                                    changeQuantity={handleChangeQuantityTable}
                                                />
                                            </td>
                                        </tr>
                                    ))}                                            
                                </tbody>
                            ))}  
                        </table>
                    </div>
                );

            case 'gridHorizontal' :
            case 'gridVertical' :

                return (
                    <>
                        {title && <><p className={styles.title}>{title}</p><hr/></>}                                  
                        <>
                            <div className={styles.productVariant + ' ' + styles[layout]}>
                                {/* <div className={styles.row}>
                                    <div className={styles.col}>
                                        <div className={styles.productImage + ' ' + styles.col}>                                            
                                        </div>
                                    </div>
                                    {variant && variant?.map((itemVariant: any, index: any) => (
                                        <div className={styles.col}>
                                            <p>{itemVariant?.type?.name}</p>
                                        </div>
                                    ))}       
                                    <div className={styles.col}>
                                        <p>Quantidade</p>
                                    </div>
                                </div> */}
                                {matrix && matrix?.map((list: any) => (
                                    <>
                                        <div key={generateKey()} className={styles[getStockStyleGrid(product, list)] + ' ' + styles.row + ' ' + styles[getSelectedStyleGrid(list)]}>

                                            <div className={styles.col + ' ' + styles.colImage}
                                                 onClick={() => handleChangeListVariant(list)}>
                                                <div className={styles.productImage}>
                                                    <ImageSet width={480} image={firstProductImage(product, list)} aspectRatio={1} />
                                                </div>
                                            </div>
                                            {list?.map((item: any) => (
                                                item.value != '_default' && <div className={styles.col} key={generateKey()}>
                                                    <p onClick={() => handleChangeListVariant(list)}>{item.label}</p>
                                                </div>
                                            ))}
                                            <div className={styles.col}>
                                                <div className={styles.itemQuantity}>
                                                    <ItemQuantity product={product} 
                                                                    variants={list}
                                                                    disabledLabel={true}
                                                                    value={getQuantityTable(list)}
                                                                    changeQuantity={handleChangeQuantityTable}/>
                                                </div>
                                            </div>                                      
                                        </div>
                                    </>
                                ))}                                    
                            </div> 
                        </>                     
                    </>)

            default :

                return null;
        }
    }
 
    return (
        <div className={styles.productVariantSelector}>
        {
            (hasStockProduct(product) ) ?  
            <>
                {displayLayout()}
                {quantityLayout()}
                
                {!disabledAddCart && <div className={styles.buyButton}>
                    <button
                        type="button"
                        disabled={loadingAddToCart}
                        onClick={() => buy()}
                        className={"block " + (loadingAddToCart ? styles['addToCart'] : '')}
                    >
                        {loadingAddToCart ? <p><FiCheck /> <span>Adicionado</span></p> : <p><FiShoppingBag /> <span>Comprar</span></p>}
                    </button>
                </div>}
            </>
            :
            !disabledAddCart && <div className={styles.soldOff}>
                <button className="block" type="button">Esgotado</button>	
            </div>
        }
        </div> 
    );
};
