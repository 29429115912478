import styles from "./styles.module.scss";
import { useState } from "react";
import { quantityStockByListVariant } from "../../../core-nextv3/stock/stock.util";

export const ItemQuantity = ({ product, variants, changeQuantity, disabledLabel=false, value=1 }: any) => 
{
    const [quantity, setQuantity] = useState(value);
    
    function handleIncrementProductQuantity() 
    {
        if(quantityStockByListVariant(product, variants) > quantity) 
        {
            setQuantity(quantity + 1);
            changeQuantity(variants, quantity + 1);
        }
    }

	function handleDecrementProductQuantity() 
    {
		if(quantity > 0) 
        {
			setQuantity(quantity - 1);
            changeQuantity(variants, quantity - 1);
		}
	}    
    return(
        <div className={styles.productQuantity}>
            {!disabledLabel && <span>Quantidade</span>}
            <div className={styles.inputQuantity}>
                <span onClick={handleDecrementProductQuantity}>-</span>
                <span className={styles.value}>{quantity}</span>
                <span onClick={handleIncrementProductQuantity}>+</span>
            </div>                          
        </div>
    );
}