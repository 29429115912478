import { BaseCollection } from "../model/base.collection";

export const firstImageItemCart = (item:any) =>
{
	if(item)
	{
		for(const key in item.images)
		{
				return item.images[key]; // TEM QUE RENORMA OBJETO
		}	
	}
}

export const hasVariantItemCart = (item: any, position: number) => 
{
	if(item && item.variant && item.variant.length > position) 
	{
	  	return item.variant[position];
	}
};

export const variantItemCart = (item: any, position: number) => 
{
	if(item && item.variant && item.variant.length > position) 
	{
		return item.variant[position].label;
	}
};

export const countItemsCart = (cart: any) => 
{
	let count = 0;

	if(cart && cart.items?.length > 0) 
	{
		// POIS PODE CONTER APENAS O BRIND
		for(const item of cart.items)
		{
			if(!item.gift)
			{
				count++;
			}
		}
	}

	return count;
};

export const getCartTable = (cart:any) => 
{
	const items = [];
	const cartTable : any = {};

	if(cart && cart.items?.length > 0) 
	{
		for(const item of cart.items)
		{
			if(!cartTable[item.product.id])
			{
				cartTable[item.product.id] = 
				{
					id 			  : item.product.id,
					code 		  : item.product.code,
					product 	  : item.product,
					images 	 	  : item.images,
					price 	 	  : item.price,
					quantityTable : {},				
					variant       : [],
				};
			}

			for(const key in item.variant)
			{
				 if(!cartTable[item.product.id].variant[key])
				 {
					cartTable[item.product.id].variant[key] = new BaseCollection();
				 }

				 cartTable[item.product.id].variant[key].set(item.variant[key]);
			}

			cartTable[item.product.id].quantityTable[item.code] = item.quantity;			
		}		

		for(const key in cartTable)
		{
			items.push(cartTable[key]);
		}
	}

	return items;
};
