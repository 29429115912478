import { THEME_SETTING } from "@/setting/setting";
import styles from "./styles.module.scss";
import Image from "next/image";

export const Certificates = () => {
  return (
    <section className={styles.certificates}>
      {/* <h1>Certificados</h1> */}
      {THEME_SETTING?.footerInfoAddress ?
        <div className={styles.certificatesListAddress}>
            <div className={styles.imagesRight}>
                <div className={styles.images}>
                    <img
                        className={styles.pagarme} 
                        // priority
                        src="/assets/icons/pagarme.svg"
                        alt="Pagarme"
                    />
                    <img
                        className={styles.letsencrypt} 
                        // priority
                        src="/assets/icons/letsencrypt.svg"
                        alt="Let's Encrypt"
                    />
                    <a href="https://binext.com.br" target="_blank" rel="noreferrer">
                        <img
                            className={styles.binext} 
                            src="/assets/binext_logo_gray.png" 
                            alt="BINEXT"
                        />
                    </a>
                </div>
                <div className={styles.address} dangerouslySetInnerHTML={{ __html: THEME_SETTING.footerInfoAddress }}></div>
            </div>
                {THEME_SETTING?.logoUpCommerce &&
                    <img
                        className={styles.upCommerce} 
                        src="/assets/up-commerce-logo.png" 
                        alt="BINEXT"
                    />
                }
        </div>
        :
            <div className={styles.certificatesList}>
                <img
                    className={styles.pagarme} 
                    // priority
                    src="/assets/icons/pagarme.svg"
                    alt="Pagarme"
                />
                <img
                    className={styles.letsencrypt} 
                    // priority
                    src="/assets/icons/letsencrypt.svg"
                    alt="Let's Encrypt"
                />
                <a href="https://binext.com.br" target="_blank" rel="noreferrer">
                    <img
                        className={styles.binext} 
                        src="/assets/binext_logo_gray.png" 
                        alt="BINEXT"
                    />
                </a>
                {THEME_SETTING?.logoUpCommerce &&
                    <img
                        className={styles.upCommerce} 
                        src="/assets/up-commerce-logo.png" 
                        alt="BINEXT"
                    />
                }
            </div>
        }
    </section>
  );
};
