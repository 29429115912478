/**
https://gist.github.com/claus/992a5596d6532ac91b24abe24e10ae81 
*/
import { useEffect, useLayoutEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { getPathFromUrl } from './util';

class UseStorage {
    static local : any = {};
}

export const useScrollRestoration = (): void => 
{
    const router = useRouter(); 

    useEffect(() => 
	{
		if(!('scrollRestoration' in window.history)) return;

		router.beforePopState(state => 
		{	
            state.options.scroll = false;

			return true;
        });

		const updateScroll = () => 
		{
            setTimeout(() => 
            {
                if(window.scrollY > 0)
                {
                    window.scrollTo(window.scrollX, window.scrollY + 1)
                }    
            }, 300)
		}

		window.addEventListener('popstate',     updateScroll);
		router.events.on('beforeHistoryChange', updateScroll);

		if(navigator.userAgent.indexOf('AppleWebKit') != -1) 
        {
			window.history.scrollRestoration = 'auto';
		} 
        else
        {
		    window.history.scrollRestoration = 'manual';
		}

		// return () => {
		// 	router.beforePopState(() => true);
		// 	window.removeEventListener('popstate',   updateScroll);
		// 	router.events.off('beforeHistoryChange', updateScroll);
		// }

	}, []);
}
 
function saveScrollPos(asPath:string) 
{
    asPath = getPathFromUrl(asPath);

    console.error(`saveScrollPos:${asPath}`, JSON.stringify({ x: window.scrollX, y: window.scrollY }));

    UseStorage.local[asPath] = JSON.stringify({ x: window.scrollX, y: window.scrollY });

    //sessionStorage.setItem(`scrollPos:${asPath}`, JSON.stringify({ x: window.scrollX, y: window.scrollY }));
}
 
 function restoreScrollPos(asPath:string) 
 {
    asPath     = getPathFromUrl(asPath);
    const json = UseStorage.local[asPath];

    //const json = sessionStorage.getItem(`scrollPos:${asPath}`);

    const scrollPos = json ? JSON.parse(json) : undefined;

    console.error(`restoreScrollPos:${asPath}`, scrollPos);

    if(scrollPos) 
    {      
        window.scrollTo(scrollPos.x, scrollPos.y + 1); 

        setTimeout(() => 
        {
            window.scrollTo(scrollPos.x, scrollPos.y + 1);
        }, 1500)
    }
 }
 
 export const useScrollRestoration2 = (): void => 
 {
    const router = useRouter();
    const [shouldScrollRestore, setShouldScrollRestore] = useState(false);

    useLayoutEffect(() => 
    {
        if(shouldScrollRestore) 
        {
            restoreScrollPos(router.asPath);
        }
    }, [shouldScrollRestore]);

    useEffect(() => 
    {
        if(!('scrollRestoration' in window.history)) return;

        if(navigator.userAgent.indexOf('AppleWebKit') != -1) 
        {
			window.history.scrollRestoration = 'auto';
		} 
        else
        {
		    window.history.scrollRestoration = 'manual';
		}

        const onBeforeUnload = (event: BeforeUnloadEvent) => 
        {
            saveScrollPos(router.asPath);
            delete event['returnValue'];
            setShouldScrollRestore(false);
        };

        const onPopstate = (event:any) => 
        {
            event.state.options.scroll = false;
            setShouldScrollRestore(true);
            //restoreScrollPos(event.state.as);
        };

        window.addEventListener('popstate',     onPopstate);
        window.addEventListener('beforeunload', onBeforeUnload);

        return () =>
        {
            window.removeEventListener('popstate',     onPopstate);
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
        
    }, [router.asPath]);
 };