import { getAccount } from "../core-nextv3/account/account.api";
import { collectionDocument, getDocument } from "../core-nextv3/document/document.api";
import { calls } from "../core-nextv3/util/call.api";
import { SEO } from "../core-nextv3/util/seo";
import { ACCOUNT_SETTING, BANNERS_TOP_PAGE_SETTING, CATEGORY_SETTING, COLOR_TABLE_SETTING, GATEWAY_SETTING, MENU_SETTING, TOP_HEADER_PAGE_SETTING } from "../setting/setting";
 
export default async function withHeader(propsFunc: any) 
{
	const [accountResult, categoriesResult, menuResult, colorTableResult, topHeader, gateway, bannersTopPage] = await calls(
		getAccount(ACCOUNT_SETTING),
		collectionDocument(CATEGORY_SETTING.merge({
			where: [
				{
					field: "_level",
					operator: "==",
					value: 1,
				},
			],
		}
		)),
		collectionDocument(MENU_SETTING.merge({
			where: [
				{
					field: "_level",
					operator: "==",
					value: 1,
				},
			],
		})),
		getDocument(COLOR_TABLE_SETTING),
		getDocument(
			TOP_HEADER_PAGE_SETTING.merge({
				document: {
				referencePath: "default/page/documents/u1rgV10IFl8mSm42He1S",
				},
			})
		),
		getDocument(GATEWAY_SETTING),
		getDocument(BANNERS_TOP_PAGE_SETTING),
	); 

	let colorTable = [];

	if(colorTableResult.data && colorTableResult.data.status)
	{
		colorTable = colorTableResult.data.table;
	}

	const account = accountResult?.data;

	const props = {
		account	   : account,
		seo 	   : SEO().mergeAccount(account),
		categories : categoriesResult?.collection || [],
		menu	   : menuResult?.collection || [],
		colorTable : colorTable,
		topHeader  : topHeader.data || {},
		gateway    : gateway?.data || {},
		bannersTopPage : bannersTopPage?.data || {}
	};

	const pageStaticProps = await propsFunc(props);
	pageStaticProps.props = { ...props, ...pageStaticProps.props };

	return pageStaticProps;
}
