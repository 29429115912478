import styles from "./styles.module.scss";
import { ItemGifts } from "./item";
import { AiOutlineClose } from "react-icons/ai";

export const ModalGifts = ({ closeModalGifts, page }: any) => {
    return(
        <div className={styles.modalGifts} onClick={() => closeModalGifts(false)}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <div className={styles.top}>
                    <AiOutlineClose onClick={() => closeModalGifts(false)} />
                </div>

                <div className={styles.body}>
                    {page?.products?.map((product:any, index:number) => (
                        <ItemGifts page={page} product={product} key={index}/>
                    ))}
                </div>
            </div>
        </div>
    );
}