import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { MENU_FOOTER_STATIC, MENU_FOOTER_STATIC_TWO } from "../../setting/setting";

export const FooterMenuDesk = () => {
  const router = useRouter();

  return (
    <>
      <section className={styles.footerMenuDesk}>
        {MENU_FOOTER_STATIC && MENU_FOOTER_STATIC?.map((item: any, index: any) => (
          <ul key={index}>
            <li><h1>{item?.label}</h1></li>
            {item?.children?.map((itemTwo: any, indexTwo: any) => (
             <li
              key={indexTwo}
              onClick={() => router.push(itemTwo?.url)}
             >
              {itemTwo?.label}
             </li>
            ))}
          </ul>
        ))}
      </section>
      <section className={styles.footerMenuDesk}>
        {MENU_FOOTER_STATIC_TWO && MENU_FOOTER_STATIC_TWO?.map((itemMenuTwo: any, indexMenuTwo) => (
          <ul key={indexMenuTwo}>
            <li><h1>{itemMenuTwo?.label}</h1></li>
            {itemMenuTwo?.children?.map((itemChildMenuTwo: any, indexChildMenuTwo: any) => (
             <li
              key={indexChildMenuTwo}
              onClick={() => router.push(itemChildMenuTwo?.url)}
             >
              {itemChildMenuTwo?.label}
            </li>
            ))}
          </ul>
        ))}
      </section>
    </>
  );
};
