import Image from "next/image";
import styles from "./styles.module.scss";
import { THEME_SETTING } from "../../setting/setting";
import { BsFacebook, BsInstagram, BsPinterest, BsYoutube } from "react-icons/bs";

export const Sac = ({ account }: any) => {
  return (
    <section className={styles.sac}>
      <div className={styles.topSac}>
        {THEME_SETTING.sacInfo2 &&
          <div>
            <h1>DEVOLUÇÃO</h1>
            <div dangerouslySetInnerHTML={{ __html: THEME_SETTING.sacInfo2 }}></div>
          </div>
        }
        <div>
          <h1>
            {/* <i>
              <img
                width={20}
                height={20}
                src="/assets/icons/sac.svg"
                alt="SAC"
              />
            </i>  */}
            SAC
          </h1>
          <div dangerouslySetInnerHTML={{ __html: THEME_SETTING.sacInfo1 }}></div>
        </div>
      </div>

      <div className={styles.socials}>
          {account?.instagram && <BsInstagram
            onClick={() => window.open(account?.instagram, "_blank")}
          />}

          {account?.pinterest && <BsPinterest
            onClick={() => window.open(account?.pinterest, "_blank")}
          />}

          {account?.facebook && <BsFacebook
            onClick={() => window.open(account?.facebook, "_blank")}
          />}

          {account?.youtube && <BsYoutube
            onClick={() => window.open(account?.youtube, "_blank")}
          />}
        </div>
      {/* <div>
        <h1>
          <i>
            <img
              width={20}
              height={20}
              src="/assets/icons/whatsapp.svg"
              alt="WhatsApp"
            />
          </i>
          SAC
        </h1>
        <div dangerouslySetInnerHTML={{ __html: THEME_SETTING.sacInfo2 }}></div>
      </div> */}
    </section>
  );
};
